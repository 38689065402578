import {
  Box,
  Button,
  Grommet,
  Spinner,
  Text,
  TextArea,
  Accordion,
  AccordionPanel,
} from 'grommet';
//@ts-ignore
import { IframeMessageProxy } from 'iframe-message-proxy';
import ReviewPointsTabComponent from './components/ReviewPointsTab.component';
import CampaignSummaryComponent from './components/CampaignSummary.component';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { toast } from 'react-toastify';
import { campaignService } from '../../service/campaign.service';
import { showToast } from '../../providers/ToastProvider';
import showModal from '../../components/modal/modal';
import ImageCard from '../../components/ImageCard';
import { defaultTheme } from '../../theme';
import ExternalFilesList from './components/ExternalFilesList.component';
import { useDispatch, useSelector } from 'react-redux';
import {
  campaignCsvGenerationModalCancelAction,
  campaignCsvGenerationModalProceedAction,
  campaignCsvGenerationResetAction,
  downloadGeneratedFilesZipAction,
  getListExternalFilesAction,
  getVendorsAction,
  sendCampaignToDeveronCancelAction,
  sendCampaignToDeveronProceedAction,
  sendCampaignToDeveronResetAction,
} from '../../redux/actions/soil-campaign-actions';
import { StyledTooltip } from '../../components/common/StyledTooltip';
import {
  CampaignStatusesDisplay,
  CampaignStatusesTooltipDisplay,
  Statuses,
} from './utils/enums';
import * as React from 'react';
import { STATUS_MAP } from '../campaign-list/utils/status';
import { Article, Edit } from 'grommet-icons';
import EditCampaignModal from '../campaign-list/components/EditCampaignModal.component';
import { FileListItem } from '../../types/campaignTypes';
const PageHeader = (data: {
  refreshHeaderData: boolean;
  vendors: any;
  campaignData: any;
  reloadCampaignData: (data: any) => void;
}) => {
  const {
    createJobFromDeveronApiHaag,
    createJobFromDeveronApiDeveron,
    createJobFromDeveronFilesDeveron,
    createJobFromDeveronFilesHaag,
  } = useFlags();
  const dispatch = useDispatch();

  const { campaignCsvGenerationState, sendCampaignToDeveron, externalFiles } =
    useSelector((state: any) => {
      return {
        campaignCsvGenerationState: state.soilCampaign.campaignCsvGeneration,
        sendCampaignToDeveron: state.soilCampaign.sendCampaignToDeveron,
        externalFiles: state.soilCampaign.campaignExternalFiles || [],
      };
    });

  const getGenerateButtonLabel = useCallback(() => {
    if (
      data.campaignData?.status === Statuses['in-review'] ||
      data.campaignData?.status === Statuses['awaiting-sync']
    ) {
      return <>{`Generate files`}</>;
    }
    return <>{`Download files`}</>;
  }, [data.campaignData?.status]);

  const getSendButtonLabel = useCallback(() => {
    if (data.campaignData?.status === Statuses['awaiting-sync']) {
      return (
        <Box direction='row' alignContent={'center'} justify={'center'}>
          <Spinner size={'small'} color={'#282828'} />
        </Box>
      );
    }
    return <>{`Send via API`}</>;
  }, [data.campaignData?.status]);

  const getDownloadUrl = useCallback(
    (newExternalFiles?: FileListItem[]) => {
      if (!externalFiles || !externalFiles?.length) return null;

      const zipFileNameRegex = new RegExp(
        `^(.*)_((19|20)[0-9][0-9])-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])T([01][1-9]|2[0-3]):([0-5][0-9]):([0-5][0-9]).([0-9][0-9][0-9])[Zz].${
          data.vendors?.[data.campaignData.vendorId] === 'Deveron'
            ? 'zip'
            : 'csv'
        }$`,
        'g',
      );
      const fileList = newExternalFiles ?? externalFiles;
      const generationFile = fileList
        .filter((f: any) => f.fileName.match(zipFileNameRegex))
        .sort(
          (a: FileListItem, b: FileListItem) =>
            new Date(a.modifiedTime) > new Date(b.modifiedTime),
        )
        .pop();
      return generationFile?.downloadUrl;
    },
    [externalFiles, data.campaignData.vendorId, data.vendors],
  );

  const [downloadURL, setDownloadURL] = useState(getDownloadUrl());
  const [generateButtonLabel, setGenerateButtonLabel] = useState(
    getGenerateButtonLabel(),
  );
  const [sendButtonLabel, setSendButtonLabel] = useState(getSendButtonLabel());
  const [resultsCsvButtonLabel, setResultsCsvButtonLabel] = useState(
    <>Generate Results CSV</>,
  );
  const [cancelModal, setCancelModal] = useState(null);
  const notes = useRef(data.campaignData.notes ?? '');
  const newCampaignData = useRef<{
    name: null | string;
    vendorId: null | string;
  }>({ name: null, vendorId: null });

  const hasBoundariesImported = data.campaignData.boundaryUrl;
  const allJobsDone = useMemo(() => {
    return !data.campaignData.jobs.some(
      (job: any) => job.status !== Statuses['completed'],
    );
  }, [data.campaignData.jobs]);
  const showButton = useMemo(() => {
    return data.campaignData.jobs.some(
      (job: any) =>
        job.status === Statuses['lab-analysis'] ||
        job.status === Statuses['completed'],
    );
  }, [data.campaignData.jobs]);

  useEffect(() => {
    setDownloadURL(getDownloadUrl());
  }, [getDownloadUrl]);

  useEffect(() => {
    notes.current = data.campaignData?.notes;
  }, [data.campaignData?.notes]);

  useEffect(() => {
    setGenerateButtonLabel(getGenerateButtonLabel());
  }, [getGenerateButtonLabel]);

  useEffect(() => {
    setSendButtonLabel(getSendButtonLabel());
  }, [getSendButtonLabel]);

  useEffect(() => {
    if (campaignCsvGenerationState.lifeCycle === 'CANCEL') {
      cancelModal.callback('CANCEL');
      dispatch(campaignCsvGenerationResetAction());
    }
    if (campaignCsvGenerationState.lifeCycle === 'PROCEED') {
      cancelModal.callback('PROCEED');
      setGenerateButtonLabel(
        <Box direction='row' alignContent={'center'} justify={'center'}>
          <Spinner size={'small'} color={'#282828'} />
        </Box>,
      );
    }
    if (campaignCsvGenerationState.lifeCycle === 'DONE') {
      data.reloadCampaignData(campaignCsvGenerationState.updatedCampaign);
      dispatch(campaignCsvGenerationResetAction());
      setGenerateButtonLabel(getGenerateButtonLabel());
    }
    if (campaignCsvGenerationState.lifeCycle === 'ERROR') {
      dispatch(campaignCsvGenerationResetAction());
      setGenerateButtonLabel(getGenerateButtonLabel());
      showToast('Error', campaignCsvGenerationState?.errorMessage, 'error');
    }

    if (sendCampaignToDeveron.lifeCycle === 'CANCEL') {
      cancelModal.callback('CANCEL');
      dispatch(sendCampaignToDeveronResetAction());
    }
    if (sendCampaignToDeveron.lifeCycle === 'PROCEED') {
      cancelModal.callback('PROCEED');
      setSendButtonLabel(
        <Box direction='row' alignContent={'center'} justify={'center'}>
          <Spinner size={'small'} color={'#282828'} />
        </Box>,
      );
    }
    if (sendCampaignToDeveron.lifeCycle === 'DONE') {
      data.reloadCampaignData({
        ...data.campaignData,
        ...sendCampaignToDeveron.updatedCampaign,
      });
      dispatch(sendCampaignToDeveronResetAction());
      setSendButtonLabel(getSendButtonLabel());
      if (sendCampaignToDeveron.updatedCampaign?.failedJobs?.length) {
        showToast(
          'Warning',
          'A few jobs failed to be created. Notify IT',
          'warning',
          null,
          null,
          null,
          true,
        );
      }
    }
    if (sendCampaignToDeveron.lifeCycle === 'ERROR') {
      dispatch(sendCampaignToDeveronResetAction());
      setSendButtonLabel(getSendButtonLabel());
      showToast(
        'Error',
        sendCampaignToDeveron?.errorMessage,
        'error',
        null,
        null,
        null,
        true,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    campaignCsvGenerationState?.lifeCycle,
    sendCampaignToDeveron?.lifeCycle,
    cancelModal,
    dispatch,
  ]);

  function fileButtonHandler(isSendToDeveronAPI: boolean) {
    if (!hasBoundariesImported) {
      showToast(
        'Warning',
        'You must upload the field boundaries before generating files for Deveron.',
        'warning',
      );
      return;
    }
    if (data.campaignData?.status === Statuses['in-review'] || !downloadURL) {
      const cancelModal = showModal(
        !isSendToDeveronAPI
          ? 'Generate Vendor Files'
          : 'Send Campaign to Deveron',
        <SendCampaignModalContent
          pointsInReview={data.campaignData.samplingPoints?.reduce(
            (t: number, point: any) => {
              if (point.status === 'IN_REVIEW') return t + 1;
              return t;
            },
            0,
          )}
          sendToDeveronAPI={isSendToDeveronAPI}
        />,
        () => {
          if (!isSendToDeveronAPI) {
            dispatch(
              campaignCsvGenerationModalProceedAction(
                data.campaignData.id,
                data.campaignData.name,
                data.campaignData.vendorId,
              ),
            );
            return;
          }

          dispatch(sendCampaignToDeveronProceedAction(data.campaignData.id));
        },
        () => {
          if (!isSendToDeveronAPI) {
            dispatch(campaignCsvGenerationModalCancelAction());
            return;
          }

          dispatch(sendCampaignToDeveronCancelAction());
        },
        true,
        'Yes, continue',
      );
      setCancelModal({ callback: cancelModal });
      return;
    }
    isSendToDeveronAPI
      ? setSendButtonLabel(
          <Box direction='row' alignContent={'center'} justify={'center'}>
            <Spinner size={'small'} color={'#282828'} />
          </Box>,
        )
      : setGenerateButtonLabel(
          <Box direction='row' alignContent={'center'} justify={'center'}>
            <Spinner size={'small'} color={'#282828'} />
          </Box>,
        );

    campaignService.getExternalFilesList(data.campaignData.id).then((res) => {
      downloadGeneratedFilesZipAction(getDownloadUrl(res.data));
      isSendToDeveronAPI
        ? setSendButtonLabel(getSendButtonLabel())
        : setGenerateButtonLabel(getGenerateButtonLabel());
    });
  }

  const shouldShowGenerateButton = useCallback(() => {
    const shouldShowDownload =
      (data.vendors?.[data.campaignData.vendorId] === 'Deveron' &&
        createJobFromDeveronFilesDeveron) ||
      (data.vendors?.[data.campaignData.vendorId] === 'HA-AG' &&
        createJobFromDeveronFilesHaag) ||
      data.vendors?.[data.campaignData.vendorId] === 'ChrysaLabs' ||
      data.campaignData.status === Statuses['files-generated'];

    return (
      data.campaignData.status !== Statuses['errored'] && shouldShowDownload
    );
  }, [
    data.vendors,
    data.campaignData.vendorId,
    data.campaignData.status,
    createJobFromDeveronFilesDeveron,
    createJobFromDeveronFilesHaag,
  ]);

  const shouldShowSendButton = useCallback(() => {
    const shouldShowSend =
      ((data.vendors?.[data.campaignData.vendorId] === 'Deveron' &&
        createJobFromDeveronApiDeveron) ||
        (data.vendors?.[data.campaignData.vendorId] === 'HA-AG' &&
          createJobFromDeveronApiHaag)) &&
      (data.campaignData.status === Statuses['in-review'] ||
        data.campaignData.status === Statuses['awaiting-sync']);

    return data.campaignData.status !== Statuses['errored'] && shouldShowSend;
  }, [
    data.vendors,
    data.campaignData.vendorId,
    data.campaignData.status,
    createJobFromDeveronApiDeveron,
    createJobFromDeveronApiHaag,
  ]);

  return (
    <Box style={{ justifyContent: 'space-between' }} direction={'row'}>
      <Box
        style={{
          flexGrow: 1,
        }}
      >
        <Box direction={'row'}>
          <Text
            style={{
              fontSize: '24px',
              lineHeight: '40px',
              fontWeight: 'bold',
              textOverflow: 'ellipsis',
              maxWidth: '50vw',
              display: 'block',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              color:
                data.campaignData.status === Statuses['errored']
                  ? '#D03450'
                  : undefined,
            }}
          >
            {data.campaignData.name}
          </Text>
          <Article
            style={{
              alignSelf: 'center',
              marginLeft: '8px',
              cursor: 'pointer',
            }}
            onClick={() => {
              showModal(
                'Notes',
                <UpdateNotesModalContent
                  value={notes.current}
                  setFunction={(newNotes: string) => (notes.current = newNotes)}
                />,
                () => {
                  data.reloadCampaignData({ notes: notes.current });
                  campaignService
                    .updateCampaign(data.campaignData.id, {
                      notes: notes.current,
                    })
                    .then((res) => {
                      data.reloadCampaignData(res.data);
                    });
                },
                () => {
                  notes.current = data.campaignData.notes ?? '';
                },
                true,
                'Save',
              );
            }}
          />
          <Edit
            style={{
              alignSelf: 'center',
              marginLeft: '8px',
              cursor: 'pointer',
            }}
            onClick={() => {
              showModal(
                'Edit your campaign',
                <EditCampaignModal
                  campaignName={data.campaignData.name}
                  campaignVendor={data.campaignData.vendorId}
                  campaignNameChanged={(name: string) => {
                    newCampaignData.current.name = name;
                  }}
                  campaignVendorChanged={(vendorId: string) => {
                    newCampaignData.current.vendorId = vendorId;
                  }}
                />,
                () => {
                  data.reloadCampaignData({
                    name:
                      newCampaignData.current.name ?? data.campaignData.name,
                    vendorId:
                      newCampaignData.current.vendorId ??
                      data.campaignData.vendorId,
                  });
                  campaignService
                    .updateCampaign(data.campaignData.id, {
                      campaignName: newCampaignData.current.name,
                      vendorId: newCampaignData.current.vendorId,
                    })
                    .then((res) => {
                      data.reloadCampaignData(res.data);
                    });
                },
                () => {
                  newCampaignData.current.name = null;
                  newCampaignData.current.vendorId = null;
                },
                true,
                'Save',
              );
            }}
          />
        </Box>
        <Box direction={'row'}>
          <StyledTooltip
            key={data.campaignData.name}
            label={
              CampaignStatusesTooltipDisplay[
                data.campaignData
                  .status as keyof typeof CampaignStatusesTooltipDisplay
              ]
            }
            disabled={
              !CampaignStatusesTooltipDisplay[
                data.campaignData
                  .status as keyof typeof CampaignStatusesTooltipDisplay
              ]
            }
          >
            <Box
              direction={'row'}
              style={{
                borderRadius: '2px',
                marginRight: '4px',
                background:
                  STATUS_MAP[data.campaignData.status]?.background ?? '#CCC',
                color: STATUS_MAP[data.campaignData.status]?.color ?? '#fff',
                paddingLeft: '8px',
                paddingRight: '8px',
                paddingTop: '4px',
                paddingBottom: '4px',
                height: '24px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontWeight: 700,
                fontSize: '16px',
                width: 'fit-content',
                lineHeight: '16px',
              }}
            >
              {CampaignStatusesDisplay[
                data.campaignData.status as keyof typeof CampaignStatusesDisplay
              ] ?? data.campaignData.status}
            </Box>
          </StyledTooltip>
          {data.campaignData['vendorId'] && data.vendors && (
            <Text size={'16px'}>
              {' '}
              - {data.vendors?.[data.campaignData['vendorId']]}
            </Text>
          )}
        </Box>
      </Box>
      <Box alignSelf={'end'} direction={'row'} style={{ flexShrink: 0 }}>
        {!hasBoundariesImported && (
          <div>
            <input
              accept='.zip'
              id={'input-file-upload-boundaries'}
              type={'file'}
              style={{ opacity: 0 }}
              onChange={async () => {
                const customGraphics = (
                  <Box direction={'row'} align={'center'} pad={'0.5rem 1rem'}>
                    <div style={{ margin: '1rem auto' }}>
                      <Spinner color={'#FFE137'} size={'large'} />
                    </div>
                  </Box>
                );
                const modalRemove = showModal(
                  'Uploading field boundaries',
                  <Grommet theme={defaultTheme}>
                    <ImageCard
                      customGraphics={customGraphics}
                      title='The data is being processed'
                      description='This process may take a couple of minutes.  You can review your campaign later.'
                    />
                  </Grommet>,
                );
                try {
                  const uploadInput: any = document.getElementById(
                    'input-file-upload-boundaries',
                  );
                  await campaignService.uploadBoundaryFile(
                    data.campaignData.id,
                    { archive: uploadInput.files[0] },
                  );
                  await campaignService
                    .getCampaignById(data.campaignData.id)
                    .then((res) => {
                      data.reloadCampaignData(res.data);
                      const isImported = res.data.boundaryUrl;
                      modalRemove('SUCCESS');
                      if (isImported) {
                        dispatch(
                          getListExternalFilesAction(data.campaignData.id),
                        );
                        showToast(
                          'Success',
                          'Field boundaries were imported successfully',
                          'success',
                        );
                      } else {
                        showToast(
                          'Error',
                          'Sorry, but we could not import the field boundaries using this file',
                          'error',
                        );
                      }
                    });
                } catch (e) {
                  modalRemove('ERROR');
                  showToast(
                    'Error',
                    'Sorry, but we could not parse this file',
                    'error',
                  );
                }
              }}
            />

            <Button
              style={{ marginRight: '10px', marginTop: '8px' }}
              className={'generate-files-button'}
              alignSelf={'end'}
              label={'Upload field boundaries'}
              onClick={() => {
                const el: any = document.getElementById(
                  'input-file-upload-boundaries',
                );
                el.click();
              }}
            />
          </div>
        )}
        {showButton && (
          <Box style={{ marginRight: '2rem' }}>
            <StyledTooltip
              dropOptions={'bottom'}
              disabled={allJobsDone}
              contentDisabled={!allJobsDone}
              label={'Not all lab results are available yet'}
            >
              <Button
                className={'generate-files-button'}
                style={{
                  minWidth: '256px',
                  textAlign: 'center',
                  marginTop: '8px',
                }}
                alignSelf={'end'}
                label={resultsCsvButtonLabel}
                onClick={() => {
                  setResultsCsvButtonLabel(
                    <Box direction='row' alignContent={'center'}>
                      <Box
                        style={{ margin: '0 auto' }}
                        className={'generate-files-spinner'}
                      >
                        <Spinner size={'small'} color={'#282828'} />
                      </Box>
                    </Box>,
                  );
                  campaignService
                    .getResultsCsvFileUrl(
                      data.campaignData.id,
                      data.campaignData.jobs.map((job: any) => job.id),
                      `sampling_results-${data.campaignData.name}`,
                    )
                    .then((result: any) => {
                      campaignService.saveFileFromUrl(result.zipUrl);
                      setResultsCsvButtonLabel(<>Generate Results CSV</>);
                      dispatch(
                        getListExternalFilesAction(data.campaignData.id),
                      );
                    })
                    .catch(() => {
                      setResultsCsvButtonLabel(<>Generate Results CSV</>);
                    });
                }}
              />
            </StyledTooltip>
          </Box>
        )}

        <Box margin={{ top: '8px' }}>
          <Box direction={'row'}>
            {shouldShowGenerateButton() && (
              <Box margin={{ right: '8px' }}>
                <StyledTooltip
                  dropOptions={'bottom'}
                  disabled={
                    data.campaignData.status === Statuses['in-review'] ||
                    !!downloadURL
                  }
                  contentDisabled={
                    data.campaignData.status !== Statuses['in-review'] &&
                    !downloadURL
                  }
                  label={
                    data.campaignData.status !== Statuses['in-review'] &&
                    !downloadURL
                      ? 'No campaign generation file found'
                      : `Errored jobs inside campaign`
                  }
                >
                  <Button
                    className={'generate-files-button'}
                    style={{ minWidth: '256px', textAlign: 'center' }}
                    alignSelf={'end'}
                    label={generateButtonLabel}
                    onClick={() => fileButtonHandler(false)}
                  />
                </StyledTooltip>
              </Box>
            )}
            {shouldShowSendButton() && (
              <Box margin={{ right: '8px' }}>
                <Button
                  className={'generate-files-button'}
                  style={{ minWidth: '256px', textAlign: 'center' }}
                  alignSelf={'end'}
                  label={sendButtonLabel}
                  onClick={() => fileButtonHandler(true)}
                />
              </Box>
            )}
          </Box>
          {(shouldShowGenerateButton() || shouldShowSendButton()) && (
            <Text
              style={{
                marginTop: '8px',
                marginRight: '8px',
                fontSize: '12px',
                fontWeight: 400,
                textAlign: 'right',
                color: '#808080',
              }}
            >
              Only Approved points will be included
            </Text>
          )}
        </Box>
      </Box>
    </Box>
  );
};

const CampaignUploadDetailsPageV2 = () => {
  const campaignDetailsDiv = useRef(null);
  const campaignDataInterval = useRef<any | undefined>();
  const [campaignData, setCampaignData] = useState(null);
  const [activeIndex, setActiveIndex] = useState([0]);
  const [refreshHeaderData, setRefreshHeaderData] = useState(false);
  const [searchParams] = useSearchParams();
  const salesforceId = searchParams.get('salesforceId');
  const { campaignId } = useParams();
  const dispatch = useDispatch();

  IframeMessageProxy.config({
    prefix: 'adminEvent:',
  });

  const { externalFiles, vendors } = useSelector(({ soilCampaign }: any) => {
    return {
      externalFiles: soilCampaign.campaignExternalFiles || [],
      vendors: soilCampaign.vendors,
    };
  });

  const getCampaignData = useCallback(() => {
    campaignService
      .getCampaignById(campaignId)
      .then((res) => {
        setCampaignData(filterCampaignJobs(res.data));
      })
      .catch(() => {
        toast('Sorry, but we could not find this campaign.', {
          position: 'top-right',
          type: 'error',
          autoClose: 5000,
          closeOnClick: true,
        });
      });
  }, [campaignId, setCampaignData]);

  useEffect(() => {
    if (!campaignDetailsDiv.current || !campaignData || !vendors) return;
    const resizeObserver = new ResizeObserver(() => {
      IframeMessageProxy.sendMessage({
        fireAndForget: true,
        action: 'PAGE-RESIZE',
        content: `${campaignDetailsDiv.current.clientHeight + 44}px`,
      });
    });
    resizeObserver.observe(campaignDetailsDiv.current);
    return () => resizeObserver.disconnect(); // clean up
  }, [campaignDetailsDiv, campaignData, vendors]);

  useEffect(() => {
    dispatch(getListExternalFilesAction(campaignId));
    dispatch(getVendorsAction());
  }, [dispatch, campaignId]);

  useEffect(() => {
    getCampaignData();
  }, [campaignId, getCampaignData]);

  useEffect(() => {
    if (
      campaignData &&
      campaignData.status === Statuses['awaiting-sync'] &&
      !campaignDataInterval.current
    ) {
      campaignDataInterval.current = setInterval(() => getCampaignData(), 2000);
    } else if (
      campaignData &&
      campaignData.status !== Statuses['awaiting-sync'] &&
      campaignDataInterval.current
    ) {
      clearInterval(campaignDataInterval.current);
      campaignDataInterval.current = undefined;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignData]);

  const onPointsChanged = (data: any) => {
    setCampaignData(data);
    setRefreshHeaderData(true);
    setTimeout(() => {
      setRefreshHeaderData(false);
    }, 1000);
  };

  const reloadCampaignData = (data: any) => {
    setCampaignData(
      filterCampaignJobs({
        ...campaignData,
        ...data,
      }),
    );
  };

  const filterCampaignJobs = (campaign: any) => {
    if (campaign.status !== Statuses['in-review']) {
      campaign.jobs = campaign.jobs.filter(
        (job: any) => job.status !== 'no-points',
      );
    }
    return campaign;
  };

  return !campaignData || !vendors ? (
    <Box direction={'row'} align={'center'} pad={'0.5rem 1rem'}>
      <div style={{ margin: '1rem auto' }}>
        <Spinner color={'#FFE137'} size={'large'} />
      </div>
    </Box>
  ) : (
    <Box ref={campaignDetailsDiv}>
      <PageHeader
        refreshHeaderData={refreshHeaderData}
        campaignData={campaignData}
        vendors={vendors}
        reloadCampaignData={reloadCampaignData}
      />
      <Accordion
        animate={true}
        multiple={true}
        activeIndex={activeIndex}
        onActive={setActiveIndex}
      >
        <AccordionPanel
          label={
            <Text
              margin={{ left: '-24px', top: '16px', bottom: '16px' }}
              style={{
                fontSize: '17px',
                lineHeight: '21px',
                maxWidth: '277px',
                fontWeight: 600,
              }}
            >
              Review points on a map
            </Text>
          }
        >
          <Box>
            <ReviewPointsTabComponent
              onChangePoints={onPointsChanged}
              campaignData={campaignData}
            />
          </Box>
        </AccordionPanel>
        <AccordionPanel
          label={
            <Text
              margin={{ left: '-24px', top: '16px', bottom: '16px' }}
              style={{
                fontSize: '17px',
                lineHeight: '21px',
                maxWidth: '277px',
                fontWeight: 600,
              }}
            >
              Jobs List
            </Text>
          }
        >
          <Box pad='none'>
            <CampaignSummaryComponent campaignData={campaignData} />
          </Box>
        </AccordionPanel>
        <AccordionPanel
          label={
            <Text
              margin={{ left: '-24px', top: '16px', bottom: '16px' }}
              style={{
                fontSize: '17px',
                lineHeight: '21px',
                maxWidth: '277px',
                fontWeight: 600,
              }}
            >
              Download Files
            </Text>
          }
        >
          <Box pad='none'>
            <ExternalFilesList
              data={externalFiles}
              status={campaignData.status}
              errors={campaignData.errors}
              campaignId={campaignId}
              salesforceId={salesforceId}
              getCampaignData={getCampaignData}
            />
          </Box>
        </AccordionPanel>
      </Accordion>
    </Box>
  );
};

const SendCampaignModalContent = ({
  pointsInReview,
  sendToDeveronAPI,
}: {
  pointsInReview: string | number;
  sendToDeveronAPI: boolean;
}) => {
  return (
    <Box style={{ maxWidth: '500px', fontSize: '22px' }}>
      {!!pointsInReview && (
        <Text size={'22px'} margin={{ bottom: '16px' }}>
          You have {pointsInReview} points <strong>"in review"</strong> that are
          going to be changed to{' '}
          <strong style={{ color: 'red' }}>"rejected"</strong>.
        </Text>
      )}
      <Text size={'22px'} margin={{ bottom: '16px' }}>
        After {!sendToDeveronAPI ? 'generating files' : 'sending the campaign'},
        you won't be able to edit points anymore. Are you sure you want to
        continue?
      </Text>
      <Text size={'12px'}>
        It may take a few minutes to process the campaign.
      </Text>
    </Box>
  );
};

const UpdateNotesModalContent = ({
  value,
  setFunction,
}: {
  value: string;
  setFunction: any;
}) => {
  const [notes, setNotes] = useState<string>(value);

  return (
    <TextArea
      value={notes}
      onChange={(event) => {
        setFunction(event.target.value);
        setNotes(event.target.value);
      }}
      name='notes'
      size={'medium'}
      resize={'vertical'}
      placeholder={'Notes'}
      style={{
        marginTop: '8px',
        borderRadius: '5px',
        borderColor: '#CCCCCC',
        borderWidth: '1px',
        padding: '16px',
        minHeight: '66px',
      }}
    />
  );
};

export default CampaignUploadDetailsPageV2;
