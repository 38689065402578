import { AnyAction } from '@reduxjs/toolkit';

const initState: any = {
  user: null,
  accessToken: null,
  getAccessTokenFn: null,
};

const reducer = (state = initState, action: AnyAction) => {
  switch (action.type) {
    case 'USER_LOADED': {
      return {
        ...state,
        user: action.payload,
      };
    }
    case 'ACCESS_TOKEN_LOADED': {
      return {
        ...state,
        accessToken: action.payload.result,
        getAccessTokenFn: action.payload.getAccessTokenFn,
      };
    }
  }
  return state;
};
export default reducer;
