export const getUserLoadedAction = (user: any) => {
  return {
    type: 'USER_LOADED',
    payload: user,
  };
};
export const getAccessTokenAction: any =
  (getAccessTokenFn: any) => (dispatch: any) => {
    return getAccessTokenFn().then((result: any) => {
      dispatch({
        type: 'ACCESS_TOKEN_LOADED',
        payload: { result, getAccessTokenFn },
      });
    });
  };
