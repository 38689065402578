import {
  Box,
  Button,
  Grommet,
  Spinner,
  Tab,
  Tabs,
  Text,
  TextArea,
} from 'grommet';
import ReviewPointsTabComponent from './components/ReviewPointsTab.component';
import CampaignSummaryComponent from './components/CampaignSummary.component';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { toast } from 'react-toastify';
import { campaignService } from '../../service/campaign.service';
import { showToast } from '../../providers/ToastProvider';
import showModal from '../../components/modal/modal';
import ImageCard from '../../components/ImageCard';
import { defaultTheme } from '../../theme';
import ExternalFilesList from './components/ExternalFilesList.component';
import { useDispatch, useSelector } from 'react-redux';
import {
  campaignCsvGenerationModalCancelAction,
  campaignCsvGenerationModalProceedAction,
  campaignCsvGenerationResetAction,
  downloadGeneratedFilesZipAction,
  getLabsAction,
  getListExternalFilesAction,
  getVendorsAction,
  sendCampaignToDeveronCancelAction,
  sendCampaignToDeveronProceedAction,
  sendCampaignToDeveronResetAction,
} from '../../redux/actions/soil-campaign-actions';
import { StyledTooltip } from '../../components/common/StyledTooltip';
import { CampaignStatusesDisplay, Statuses } from './utils/enums';
import * as React from 'react';
import { STATUS_MAP } from '../campaign-list/utils/status';
import { Article, Edit } from 'grommet-icons';
import EditCampaignModal from '../campaign-list/components/EditCampaignModal.component';
import { FileListItem } from '../../types/campaignTypes';
import { getCampaignLab } from '../../common/helpers';

const PageHeader = (data: {
  refreshHeaderData: boolean;
  vendors: any;
  campaignData: any;
  reloadCampaignData: any;
}) => {
  const {
    createJobFromDeveronApiHaag,
    createJobFromDeveronApiDeveron,
    selectCampaignLabs,
    selectCampaignTracking,
    showSamplingCycle,
  } = useFlags();
  const dispatch = useDispatch();

  const {
    campaignCsvGenerationState,
    sendCampaignToDeveron,
    externalFiles,
    labs,
  } = useSelector((state: any) => {
    return {
      campaignCsvGenerationState: state.soilCampaign.campaignCsvGeneration,
      sendCampaignToDeveron: state.soilCampaign.sendCampaignToDeveron,
      externalFiles: state.soilCampaign.campaignExternalFiles || [],
      labs: state.soilCampaign.labs,
    };
  });

  const getGenerateButtonLabel = useCallback(() => {
    if (
      data.campaignData?.status === Statuses['in-review'] ||
      data.campaignData?.status === Statuses['awaiting-sync']
    ) {
      return <>{`Generate files`}</>;
    }
    return <>{`Download files`}</>;
  }, [data.campaignData?.status]);

  const getSendButtonLabel = useCallback(() => {
    if (data.campaignData?.status === Statuses['awaiting-sync']) {
      return (
        <Box direction='row' alignContent={'center'} justify={'center'}>
          <Spinner size={'small'} color={'#282828'} />
        </Box>
      );
    }
    return <>{`Send via API`}</>;
  }, [data.campaignData?.status]);

  const getDownloadUrl = useCallback(
    (newExternalFiles?: FileListItem[]) => {
      if (!externalFiles || !externalFiles?.length) return null;

      const zipFileNameRegex = new RegExp(
        `^(.*)_((19|20)[0-9][0-9])-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])T([01][1-9]|2[0-3]):([0-5][0-9]):([0-5][0-9]).([0-9][0-9][0-9])[Zz].zip$`,
        'g',
      );
      const fileList = newExternalFiles ?? externalFiles;
      const generationFile = fileList
        .filter((f: any) => f.fileName.match(zipFileNameRegex))
        .sort(
          (a: FileListItem, b: FileListItem) =>
            new Date(a.modifiedTime) > new Date(b.modifiedTime),
        )
        .pop();
      return generationFile?.downloadUrl;
    },
    [externalFiles],
  );

  const [downloadURL, setDownloadURL] = useState(getDownloadUrl());
  const [generateButtonLabel, setGenerateButtonLabel] = useState(
    getGenerateButtonLabel(),
  );
  const [sendButtonLabel, setSendButtonLabel] = useState(getSendButtonLabel());
  const [resultsCsvButtonLabel, setResultsCsvButtonLabel] = useState(
    <>Generate Results CSV</>,
  );
  const [cancelModal, setCancelModal] = useState(null);
  const notes = useRef(data.campaignData.notes ?? '');
  const newCampaignData = useRef<{
    campaignName: null | string;
    vendorId: null | string;
    labId: null | string;
    assessedStatus: null | string;
    vendorForCleanup: null | string;
    validCollectedPoints: null | number;
  }>({
    campaignName: null,
    vendorId: null,
    labId: null,
    assessedStatus: null,
    vendorForCleanup: null,
    validCollectedPoints: null,
  });

  const hasBoundariesImported = data.campaignData.boundaryUrl;
  const allJobsDone = useMemo(() => {
    return !data.campaignData.jobs.some(
      (job: any) => job.status !== Statuses['completed'],
    );
  }, [data.campaignData.jobs]);
  const showButton = useMemo(() => {
    return data.campaignData.jobs.some(
      (job: any) =>
        job.status === Statuses['lab-analysis'] ||
        job.status === Statuses['completed'],
    );
  }, [data.campaignData.jobs]);

  useEffect(() => {
    setDownloadURL(getDownloadUrl());
  }, [getDownloadUrl]);

  useEffect(() => {
    notes.current = data.campaignData?.notes;
  }, [data.campaignData?.notes]);

  useEffect(() => {
    setGenerateButtonLabel(getGenerateButtonLabel());
  }, [getGenerateButtonLabel]);

  useEffect(() => {
    setSendButtonLabel(getSendButtonLabel());
  }, [getSendButtonLabel]);

  useEffect(() => {
    if (campaignCsvGenerationState.lifeCycle === 'CANCEL') {
      cancelModal.callback('CANCEL');
      dispatch(campaignCsvGenerationResetAction());
    }
    if (campaignCsvGenerationState.lifeCycle === 'PROCEED') {
      cancelModal.callback('PROCEED');
      setGenerateButtonLabel(
        <Box direction='row' alignContent={'center'} justify={'center'}>
          <Spinner size={'small'} color={'#282828'} />
        </Box>,
      );
    }
    if (campaignCsvGenerationState.lifeCycle === 'DONE') {
      data.reloadCampaignData(campaignCsvGenerationState.updatedCampaign);
      dispatch(campaignCsvGenerationResetAction());
      setGenerateButtonLabel(getGenerateButtonLabel());
    }
    if (campaignCsvGenerationState.lifeCycle === 'ERROR') {
      dispatch(campaignCsvGenerationResetAction());
      setGenerateButtonLabel(getGenerateButtonLabel());
      showToast('Error', campaignCsvGenerationState?.errorMessage, 'error');
    }

    if (sendCampaignToDeveron.lifeCycle === 'CANCEL') {
      cancelModal.callback('CANCEL');
      dispatch(sendCampaignToDeveronResetAction());
    }
    if (sendCampaignToDeveron.lifeCycle === 'PROCEED') {
      cancelModal.callback('PROCEED');
      setSendButtonLabel(
        <Box direction='row' alignContent={'center'} justify={'center'}>
          <Spinner size={'small'} color={'#282828'} />
        </Box>,
      );
    }
    if (sendCampaignToDeveron.lifeCycle === 'DONE') {
      data.reloadCampaignData({
        ...data.campaignData,
        ...sendCampaignToDeveron.updatedCampaign,
      });
      dispatch(sendCampaignToDeveronResetAction());
      setSendButtonLabel(getSendButtonLabel());
      if (sendCampaignToDeveron.updatedCampaign?.failedJobs?.length) {
        showToast(
          'Warning',
          'A few jobs failed to be created. Notify IT',
          'warning',
          null,
          null,
          null,
          true,
        );
      }
    }
    if (sendCampaignToDeveron.lifeCycle === 'ERROR') {
      dispatch(sendCampaignToDeveronResetAction());
      setSendButtonLabel(getSendButtonLabel());
      showToast(
        'Error',
        sendCampaignToDeveron?.errorMessage,
        'error',
        null,
        null,
        null,
        true,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    campaignCsvGenerationState?.lifeCycle,
    sendCampaignToDeveron?.lifeCycle,
    cancelModal,
    dispatch,
  ]);

  function fileButtonHandler(isSendToDeveronAPI: boolean) {
    if (!hasBoundariesImported) {
      showToast(
        'Warning',
        'You must upload the field boundaries before generating files for Deveron.',
        'warning',
      );
      return;
    }
    if (data.campaignData?.status === Statuses['in-review'] || !downloadURL) {
      const cancelModal = showModal(
        !isSendToDeveronAPI
          ? 'Generate Vendor Files'
          : 'Send Campaign to Deveron',
        <SendCampaignModalContent
          pointsInReview={data.campaignData.samplingPoints?.reduce(
            (t: number, point: any) => {
              if (point.status === 'IN_REVIEW') return t + 1;
              return t;
            },
            0,
          )}
          sendToDeveronAPI={isSendToDeveronAPI}
        />,
        () => {
          if (!isSendToDeveronAPI) {
            dispatch(
              campaignCsvGenerationModalProceedAction(
                data.campaignData.id,
                data.campaignData.name,
                data.campaignData.vendorId,
              ),
            );
            return;
          }

          dispatch(sendCampaignToDeveronProceedAction(data.campaignData.id));
        },
        () => {
          if (!isSendToDeveronAPI) {
            dispatch(campaignCsvGenerationModalCancelAction());
            return;
          }

          dispatch(sendCampaignToDeveronCancelAction());
        },
        true,
        'Yes, continue',
        'Cancel',
        'generate-or-send-modal',
      );
      setCancelModal({ callback: cancelModal });
      return;
    }
    isSendToDeveronAPI
      ? setSendButtonLabel(
          <Box direction='row' alignContent={'center'} justify={'center'}>
            <Spinner size={'small'} color={'#282828'} />
          </Box>,
        )
      : setGenerateButtonLabel(
          <Box direction='row' alignContent={'center'} justify={'center'}>
            <Spinner size={'small'} color={'#282828'} />
          </Box>,
        );

    campaignService.getExternalFilesList(data.campaignData.id).then((res) => {
      downloadGeneratedFilesZipAction(getDownloadUrl(res.data));
      isSendToDeveronAPI
        ? setSendButtonLabel(getSendButtonLabel())
        : setGenerateButtonLabel(getGenerateButtonLabel());
    });
  }

  const shouldShowGenerateButton = useCallback(() => {
    const shouldShowDownload =
      data.campaignData.status === Statuses['in-review'] ||
      data.campaignData.status === Statuses['awaiting-sync'] ||
      data.campaignData.status === Statuses['files-generated'] ||
      !!downloadURL;

    return (
      data.campaignData.status !== Statuses['errored'] && shouldShowDownload
    );
  }, [data.campaignData.status, downloadURL]);

  const shouldShowSendButton = useCallback(() => {
    const shouldShowSend =
      ((data.vendors?.[data.campaignData.vendorId] === 'Deveron' &&
        createJobFromDeveronApiDeveron) ||
        (data.vendors?.[data.campaignData.vendorId] === 'HA-AG' &&
          createJobFromDeveronApiHaag)) &&
      (data.campaignData.status === Statuses['in-review'] ||
        data.campaignData.status === Statuses['awaiting-sync']);

    return data.campaignData.status !== Statuses['errored'] && shouldShowSend;
  }, [
    data.vendors,
    data.campaignData.vendorId,
    data.campaignData.status,
    createJobFromDeveronApiDeveron,
    createJobFromDeveronApiHaag,
  ]);

  const labName = useMemo(() => {
    return getCampaignLab(data.campaignData)
      ?.map((id) => labs[id])
      .join(', ');
  }, [data.campaignData, labs]);

  return (
    <Box
      pad={'small'}
      style={{ justifyContent: 'space-between' }}
      direction={'row'}
    >
      <Box
        style={{
          flexGrow: 1,
          paddingLeft: '8px',
        }}
      >
        <Box direction={'row'}>
          <Text
            data-cy={'campaign-name-header'}
            style={{
              fontSize: '24px',
              lineHeight: '40px',
              fontWeight: 'bold',
              textOverflow: 'ellipsis',
              maxWidth: '50vw',
              display: 'block',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              color:
                data.campaignData.status === Statuses['errored']
                  ? '#D03450'
                  : undefined,
            }}
          >
            {data.campaignData.name}
          </Text>
          <Article
            data-cy={'edit-campaign-notes-button'}
            style={{
              alignSelf: 'center',
              marginLeft: '8px',
              cursor: 'pointer',
            }}
            onClick={() => {
              showModal(
                'Notes',
                <UpdateNotesModalContent
                  value={notes.current}
                  setFunction={(newNotes: string) => (notes.current = newNotes)}
                />,
                () => {
                  data.reloadCampaignData({ notes: notes.current });
                  campaignService
                    .updateCampaign(data.campaignData.id, {
                      notes: notes.current,
                    })
                    .then((res) => {
                      data.reloadCampaignData(res.data);
                    });
                },
                () => {
                  notes.current = data.campaignData.notes ?? '';
                },
                true,
                'Save',
                'Cancel',
                'notes-modal',
              );
            }}
          />
          <Edit
            data-cy={'edit-campaign-button'}
            style={{
              alignSelf: 'center',
              marginLeft: '8px',
              cursor: 'pointer',
            }}
            onClick={() => {
              showModal(
                'Edit your campaign',
                <EditCampaignModal
                  campaign={data.campaignData}
                  campaignPropUpdated={(
                    prop:
                      | 'campaignName'
                      | 'vendorId'
                      | 'labId'
                      | 'assessedStatus'
                      | 'vendorForCleanup'
                      | 'validCollectedPoints',
                    value: string | number,
                  ) => {
                    // @ts-ignore
                    newCampaignData.current[prop] = value;
                  }}
                  showCampaignLab={selectCampaignLabs}
                  showCampaignTracking={selectCampaignTracking}
                />,
                () => {
                  data.reloadCampaignData({
                    name:
                      newCampaignData.current.campaignName ??
                      data.campaignData.name,
                    vendorId:
                      newCampaignData.current.vendorId ??
                      data.campaignData.vendorId,
                    labId:
                      newCampaignData.current.labId ?? data.campaignData.labId,
                    assessedStatus:
                      newCampaignData.current.assessedStatus ??
                      data.campaignData.assessedStatus,
                    vendorForCleanup:
                      newCampaignData.current.vendorForCleanup ??
                      data.campaignData.vendorForCleanupId,
                    validCollectedPoints:
                      newCampaignData.current.validCollectedPoints ??
                      data.campaignData.validCollectedPoints,
                  });
                  campaignService
                    .updateCampaign(
                      data.campaignData.id,
                      newCampaignData.current,
                    )
                    .then((res) => {
                      data.reloadCampaignData(res.data);
                    });
                },
                () => {
                  newCampaignData.current.campaignName = null;
                  newCampaignData.current.vendorId = null;
                  newCampaignData.current.assessedStatus = null;
                  newCampaignData.current.vendorForCleanup = null;
                  newCampaignData.current.validCollectedPoints = null;
                },
                true,
                'Save',
                'Cancel',
                'edit-modal',
              );
            }}
          />
        </Box>
        <Box direction={'row'}>
          <Box
            data-cy={'campaign-status-display'}
            direction={'row'}
            style={{
              borderRadius: '2px',
              marginRight: '4px',
              background:
                STATUS_MAP[data.campaignData.status]?.background ?? '#CCC',
              color: STATUS_MAP[data.campaignData.status]?.color ?? '#fff',
              paddingLeft: '8px',
              paddingRight: '8px',
              paddingTop: '4px',
              paddingBottom: '4px',
              height: '24px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontWeight: 700,
              fontSize: '16px',
              width: 'fit-content',
              lineHeight: '16px',
            }}
          >
            {CampaignStatusesDisplay[
              data.campaignData.status as keyof typeof CampaignStatusesDisplay
            ] ?? data.campaignData.status}
          </Box>
          {data.campaignData['vendorId'] && data.vendors && (
            <Text size={'16px'}>
              {' '}
              - {data.vendors?.[data.campaignData['vendorId']]}
            </Text>
          )}
          {labName && (
            <Text size={'16px'} margin={{ left: '3px' }}>
              - {labName}
            </Text>
          )}
          {showSamplingCycle && data.campaignData['cycle'] && (
            <Text size={'16px'} margin={{ left: '3px' }}>
              - Cycle {data.campaignData['cycle']}
            </Text>
          )}
        </Box>
      </Box>
      <Box alignSelf={'end'} direction={'row'} style={{ flexShrink: 0 }}>
        {!hasBoundariesImported && (
          <div>
            <input
              accept='.zip'
              id={'input-file-upload-boundaries'}
              type={'file'}
              style={{ opacity: 0 }}
              onChange={async () => {
                const customGraphics = (
                  <Box direction={'row'} align={'center'} pad={'0.5rem 1rem'}>
                    <div style={{ margin: '1rem auto' }}>
                      <Spinner color={'#FFE137'} size={'large'} />
                    </div>
                  </Box>
                );
                const modalRemove = showModal(
                  'Uploading field boundaries',
                  <Grommet theme={defaultTheme}>
                    <ImageCard
                      customGraphics={customGraphics}
                      title='The data is being processed'
                      description='This process may take a couple of minutes.  You can review your campaign later.'
                    />
                  </Grommet>,
                  null,
                  null,
                  true,
                  'Okay',
                  'Cancel',
                  'upload-boundaries-modal',
                );
                try {
                  const uploadInput: any = document.getElementById(
                    'input-file-upload-boundaries',
                  );
                  await campaignService.uploadBoundaryFile(
                    data.campaignData.id,
                    { archive: uploadInput.files[0] },
                  );
                  await campaignService
                    .getCampaignById(data.campaignData.id)
                    .then((res) => {
                      data.reloadCampaignData(res.data);
                      const isImported = res.data.boundaryUrl;
                      modalRemove('SUCCESS');
                      if (isImported) {
                        dispatch(
                          getListExternalFilesAction(data.campaignData.id),
                        );
                        showToast(
                          'Success',
                          'Field boundaries were imported successfully',
                          'success',
                        );
                      } else {
                        showToast(
                          'Error',
                          'Sorry, but we could not import the field boundaries using this file',
                          'error',
                        );
                      }
                    });
                } catch (e) {
                  modalRemove('ERROR');
                  showToast(
                    'Error',
                    'Sorry, but we could not parse this file',
                    'error',
                  );
                }
              }}
            />
            <Button
              data-cy={'upload-field-boundaries-button'}
              style={{ marginRight: '10px', marginTop: '8px' }}
              className={'generate-files-button'}
              alignSelf={'end'}
              label={'Upload field boundaries'}
              onClick={() => {
                const el: any = document.getElementById(
                  'input-file-upload-boundaries',
                );
                el.click();
              }}
            />
          </div>
        )}
        {showButton && (
          <Box style={{ marginRight: '2rem' }}>
            <StyledTooltip
              dropOptions={'bottom'}
              disabled={allJobsDone}
              contentDisabled={!allJobsDone}
              label={'Not all lab results are available yet'}
            >
              <Button
                data-cy={'results-csv-button'}
                className={'generate-files-button'}
                style={{
                  minWidth: '256px',
                  textAlign: 'center',
                  marginTop: '8px',
                }}
                alignSelf={'end'}
                label={resultsCsvButtonLabel}
                onClick={() => {
                  setResultsCsvButtonLabel(
                    <Box direction='row' alignContent={'center'}>
                      <Box
                        style={{ margin: '0 auto' }}
                        className={'generate-files-spinner'}
                      >
                        <Spinner size={'small'} color={'#282828'} />
                      </Box>
                    </Box>,
                  );
                  campaignService
                    .getResultsCsvFileUrl(
                      data.campaignData.id,
                      data.campaignData.jobs.map((job: any) => job.id),
                      `sampling_results-${data.campaignData.name}`,
                    )
                    .then((result: any) => {
                      campaignService.saveFileFromUrl(result.zipUrl);
                      setResultsCsvButtonLabel(<>Generate Results CSV</>);
                      dispatch(
                        getListExternalFilesAction(data.campaignData.id),
                      );
                    })
                    .catch(() => {
                      setResultsCsvButtonLabel(<>Generate Results CSV</>);
                    });
                }}
              />
            </StyledTooltip>
          </Box>
        )}

        <Box margin={{ top: '8px' }}>
          <Box direction={'row'}>
            {shouldShowGenerateButton() && (
              <Box margin={{ right: '8px' }}>
                <StyledTooltip
                  dropOptions={'bottom'}
                  disabled={
                    data.campaignData.status === Statuses['in-review'] ||
                    !!downloadURL
                  }
                  contentDisabled={
                    data.campaignData.status !== Statuses['in-review'] &&
                    !downloadURL
                  }
                  label={
                    data.campaignData.status !== Statuses['in-review'] &&
                    !downloadURL
                      ? 'No campaign generation file found'
                      : `Errored jobs inside campaign`
                  }
                >
                  <Button
                    data-cy={'generate-files-button'}
                    className={'generate-files-button'}
                    style={{ minWidth: '256px', textAlign: 'center' }}
                    alignSelf={'end'}
                    label={generateButtonLabel}
                    onClick={() => fileButtonHandler(false)}
                  />
                </StyledTooltip>
              </Box>
            )}
            {shouldShowSendButton() && (
              <Box margin={{ right: '8px' }}>
                <Button
                  data-cy={'send-via-api-button'}
                  className={'generate-files-button'}
                  style={{ minWidth: '256px', textAlign: 'center' }}
                  alignSelf={'end'}
                  label={sendButtonLabel}
                  onClick={() => fileButtonHandler(true)}
                />
              </Box>
            )}
          </Box>
          {(shouldShowGenerateButton() || shouldShowSendButton()) && (
            <Text
              style={{
                marginTop: '8px',
                marginRight: '8px',
                fontSize: '12px',
                fontWeight: 400,
                textAlign: 'right',
                color: '#808080',
              }}
            >
              Only Approved points will be included
            </Text>
          )}
        </Box>
      </Box>
    </Box>
  );
};

const CampaignUploadDetailsPage = () => {
  const campaignDataInterval = useRef<any | undefined>();
  const [campaignData, setCampaignData] = useState(null);
  const [refreshHeaderData, setRefreshHeaderData] = useState(false);
  const [searchParams] = useSearchParams();
  const salesforceId = searchParams.get('salesforceId');
  const journeyId = searchParams.get('journeyId');
  const { campaignId } = useParams();
  const dispatch = useDispatch();

  const { externalFiles, vendors } = useSelector(({ soilCampaign }: any) => {
    return {
      externalFiles: soilCampaign.campaignExternalFiles || [],
      vendors: soilCampaign.vendors,
    };
  });

  const getCampaignData = useCallback(() => {
    campaignService
      .getCampaignById(campaignId)
      .then((res) => {
        setCampaignData(filterCampaignJobs(res.data));
      })
      .catch(() => {
        toast('Sorry, but we could not find this campaign.', {
          position: 'top-right',
          type: 'error',
          autoClose: 5000,
          closeOnClick: true,
        });
      });
  }, [campaignId, setCampaignData]);

  useEffect(() => {
    dispatch(getListExternalFilesAction(campaignId));
    dispatch(getVendorsAction());
    dispatch(getLabsAction());
  }, [dispatch, campaignId]);

  useEffect(() => {
    getCampaignData();
  }, [campaignId, getCampaignData]);

  useEffect(() => {
    if (
      campaignData &&
      campaignData.status === Statuses['awaiting-sync'] &&
      !campaignDataInterval.current
    ) {
      campaignDataInterval.current = setInterval(() => getCampaignData(), 2000);
    } else if (
      campaignData &&
      campaignData.status !== Statuses['awaiting-sync'] &&
      campaignDataInterval.current
    ) {
      clearInterval(campaignDataInterval.current);
      campaignDataInterval.current = undefined;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignData]);

  const onPointsChanged = (data: any) => {
    setCampaignData(data);
    setRefreshHeaderData(true);
    setTimeout(() => {
      setRefreshHeaderData(false);
    }, 1000);
  };

  const reloadCampaignData = (data: any) => {
    setCampaignData(
      filterCampaignJobs({
        ...campaignData,
        ...data,
        jobs: data?.campaignJobs || campaignData.jobs,
      }),
    );
  };

  const filterCampaignJobs = (campaign: any) => {
    if (campaign.status !== Statuses['in-review']) {
      campaign.jobs = campaign.jobs.filter(
        (job: any) => job.status !== 'no-points',
      );
    }
    return campaign;
  };

  return !campaignData || !vendors ? (
    <Box direction={'row'} align={'center'} pad={'0.5rem 1rem'}>
      <div style={{ margin: '1rem auto' }}>
        <Spinner color={'#FFE137'} size={'large'} />
      </div>
    </Box>
  ) : (
    <Box>
      <PageHeader
        refreshHeaderData={refreshHeaderData}
        campaignData={campaignData}
        vendors={vendors}
        reloadCampaignData={reloadCampaignData}
      />
      <Tabs
        data-cy={'jobs-list-tab'}
        alignControls={'start'}
        onClick={() => toast.dismiss()}
      >
        {campaignData.status !== Statuses['errored']
          ? [
              <Tab
                data-cy={'jobs-list-tab'}
                title='Jobs List'
                key='campaign-summary'
              >
                <Box pad='none'>
                  <CampaignSummaryComponent campaignData={campaignData} />
                </Box>
              </Tab>,
              <Tab
                data-cy={'review-points-tab'}
                title='Review points on a map'
                key='review-points'
              >
                <Box pad='none'>
                  <ReviewPointsTabComponent
                    onChangePoints={onPointsChanged}
                    campaignData={campaignData}
                  />
                </Box>
              </Tab>,
            ]
          : []}
        <Tab
          data-cy={'download-files-tab'}
          title={
            campaignData.status !== Statuses['errored']
              ? 'Download Files'
              : 'Error report'
          }
        >
          <Box pad='none'>
            <ExternalFilesList
              data={externalFiles}
              status={campaignData.status}
              errors={campaignData.errors}
              campaignId={campaignId}
              salesforceId={salesforceId}
              journeyId={journeyId}
              getCampaignData={getCampaignData}
            />
          </Box>
        </Tab>
      </Tabs>
    </Box>
  );
};

const SendCampaignModalContent = ({
  pointsInReview,
  sendToDeveronAPI,
}: {
  pointsInReview: string | number;
  sendToDeveronAPI: boolean;
}) => {
  return (
    <Box style={{ maxWidth: '500px', fontSize: '22px' }}>
      {!!pointsInReview && (
        <Text size={'22px'} margin={{ bottom: '16px' }}>
          You have {pointsInReview} points <strong>"in review"</strong> that are
          going to be changed to{' '}
          <strong style={{ color: 'red' }}>"rejected"</strong>.
        </Text>
      )}
      <Text size={'22px'} margin={{ bottom: '16px' }}>
        After {!sendToDeveronAPI ? 'generating files' : 'sending the campaign'},
        you won't be able to edit points anymore. Are you sure you want to
        continue?
      </Text>
      <Text size={'12px'}>
        It may take a few minutes to process the campaign.
      </Text>
    </Box>
  );
};

const UpdateNotesModalContent = ({
  value,
  setFunction,
}: {
  value: string;
  setFunction: any;
}) => {
  const [notes, setNotes] = useState<string>(value);

  return (
    <TextArea
      data-cy={'notes-input'}
      value={notes}
      onChange={(event) => {
        setFunction(event.target.value);
        setNotes(event.target.value);
      }}
      name='notes'
      size={'medium'}
      resize={'vertical'}
      placeholder={'Notes'}
      style={{
        marginTop: '8px',
        borderRadius: '5px',
        borderColor: '#CCCCCC',
        borderWidth: '1px',
        padding: '16px',
        minHeight: '66px',
      }}
    />
  );
};

export default CampaignUploadDetailsPage;
