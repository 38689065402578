import { Box, CheckBox, DropButton, Heading, Text } from 'grommet';
import { Close } from 'grommet-icons';

const MapAdaptViewComponent = (props: {
  setAdaptViewOpened: any;
  mapOverlayOptions: any;
  samplePointOptions: any;
  onAdaptViewOpened: any;
  onAdaptVieClosed: any;
  adaptViewOpened: any;
  isMultiSelectMode: any;
}) => {
  return (
    <DropButton
      data-cy={'adapt-view-button'}
      label='Adapt View'
      onOpen={props.onAdaptViewOpened}
      onClose={props.onAdaptVieClosed}
      open={props.adaptViewOpened}
      disabled={props.isMultiSelectMode}
      style={{
        width: '145px',
        zIndex: props.adaptViewOpened ? -1 : 1,
        background: '#fff',
      }}
      dropAlign={{ right: 'right', top: 'top' }}
      dropContent={
        <Box
          pad='large'
          style={{
            background: '#fff',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          }}
        >
          <Heading
            style={{ fontSize: '20px', lineHeight: '24px', fontWeight: '700' }}
          >
            Adapt the view to your needs
          </Heading>
          <Close
            data-cy={'close-adapt-view-button'}
            onClick={() => props.setAdaptViewOpened(false)}
            style={{
              cursor: 'pointer',
              position: 'absolute',
              top: '1.2rem',
              right: '1rem',
            }}
          />
          {(props.mapOverlayOptions.length && (
            <Text
              style={{
                color: '#808080',
                fontWeight: 600,
                fontSize: '16px',
                lineHeight: '24px',
              }}
            >
              Map overlays
            </Text>
          )) ||
            ''}

          {props.mapOverlayOptions.map((option: any) => {
            return (
              <Box direction={'row'} pad={'small'} key={option.label}>
                {option.icon}
                <Text
                  style={{
                    width: '215px',
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '16px',
                    lineHeight: '24px',
                    paddingLeft: '1rem',
                    paddingRight: '1rem',
                    fontWeight: 700,
                  }}
                >
                  {option.label}
                </Text>
                <CheckBox
                  data-cy={option.dataCy}
                  checked={option.checked}
                  reverse
                  toggle
                  onChange={option.onChange}
                />
              </Box>
            );
          })}

          {(props.samplePointOptions.length && (
            <Text
              style={{
                color: '#808080',
                fontWeight: 600,
                fontSize: '16px',
                lineHeight: '24px',
              }}
            >
              Sample points
            </Text>
          )) ||
            ''}

          {props.samplePointOptions.map((option: any) => {
            return (
              <Box direction={'row'} pad={'small'} key={option.label}>
                {option.icon}
                <Text
                  style={{
                    width: '215px',
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '16px',
                    lineHeight: '24px',
                    paddingLeft: '1rem',
                    paddingRight: '1rem',
                    fontWeight: 700,
                  }}
                >
                  {option.label}
                </Text>
                <CheckBox
                  data-cy={option.dataCy}
                  checked={option.checked}
                  reverse
                  toggle
                  onChange={option.onChange}
                />
              </Box>
            );
          })}
        </Box>
      }
    />
  );
};

export default MapAdaptViewComponent;
