export const getCampaignLab = (campaign: any) => {
  const labs: string[] = [];
  const jobs = campaign.jobs || campaign.campaignJobs || [campaign];
  for (const job of jobs) {
    if (job.labId && !labs.includes(job.labId)) {
      labs.push(job.labId);
    }
  }
  return labs;
};
