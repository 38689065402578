import { AnyAction } from '@reduxjs/toolkit';
import { getCampaignLab } from '../../common/helpers';

const initState: any = {
  campaigns: null,
  campaignFilter: { search: '', status: undefined },
  campaignUploadState: { lifeCycle: 'NONE' },
  campaignCreationState: { lifeCycle: 'NONE' },
  campaignListState: { lifeCycle: 'NONE', status: null },
  shouldShowArchived: false,
  campaignChangeStatusState: { lifeCycle: 'NONE' },
  vendors: {},
  vendorState: { lifeCycle: 'NONE' },
  producer: null,
  producerState: { lifeCycle: 'NONE' },
  campaignCsvGeneration: { lifeCycle: 'NONE', updatedCampaign: undefined },
  sendCampaignToDeveron: { lifeCycle: 'NONE', updatedCampaign: undefined },
};

const reducer = (state = initState, action: AnyAction) => {
  switch (action.type) {
    case 'LIST_CAMPAIGNS_REQUESTED':
      return {
        ...state,
        campaigns: null,
        producer: null,
        campaignListState: { lifeCycle: 'PENDING' },
      };
    case 'LIST_PRODUCER_CAMPAIGNS_REQUESTED':
      return {
        ...state,
        campaigns: null,
        campaignListState: { lifeCycle: 'PENDING' },
      };
    case 'LIST_CAMPAIGNS_SUCCEEDED':
      return {
        ...state,
        campaignListState: { lifeCycle: 'SUCCESS' },
        campaigns: action.payload,
      };
    case 'LIST_CAMPAIGNS_ERROR':
      return {
        ...state,
        campaignListState: {
          lifeCycle: 'ERROR',
          status: action.payload.status,
        },
      };
    case 'GET_VENDORS_REQUESTED':
      return {
        ...state,
        vendorState: { lifeCycle: 'PENDING' },
      };
    case 'GET_VENDORS_SUCCEEDED':
      return {
        ...state,
        vendorState: { lifeCycle: 'SUCCESS' },
        vendors: action.payload,
      };
    case 'GET_VENDORS_ERROR':
      return {
        ...state,
        vendorState: { lifeCycle: 'ERROR' },
      };
    case 'GET_LABS_REQUESTED':
      return {
        ...state,
        labState: { lifeCycle: 'PENDING' },
      };
    case 'GET_LABS_SUCCEEDED':
      return {
        ...state,
        labState: { lifeCycle: 'SUCCESS' },
        labs: action.payload,
      };
    case 'GET_LABS_ERROR':
      return {
        ...state,
        labState: { lifeCycle: 'ERROR' },
      };
    case 'GET_PRODUCER_REQUESTED':
      return {
        ...state,
        producerState: { lifeCycle: 'PENDING' },
      };
    case 'GET_PRODUCER_SUCCEEDED':
      return {
        ...state,
        producerState: { lifeCycle: 'SUCCESS' },
        producer: action.payload,
      };
    case 'GET_PRODUCER_ERROR':
      return {
        ...state,
        producerState: { lifeCycle: 'ERROR' },
      };
    case 'GET_JOURNEY_REQUESTED':
      return {
        ...state,
        journeyState: { lifeCycle: 'PENDING' },
      };
    case 'GET_JOURNEY_SUCCEEDED':
      return {
        ...state,
        journeyState: { lifeCycle: 'SUCCESS' },
        journey: action.payload,
      };
    case 'GET_JOURNEY_ERROR':
      return {
        ...state,
        journeyState: { lifeCycle: 'ERROR' },
      };
    case 'SOIL_CAMPAIGN_FILE_UPLOAD_REQUESTED':
      return {
        ...state,
        campaignUploadState: {
          lifeCycle: 'PENDING',
        },
      };
    case 'SOIL_CAMPAIGN_FILE_UPLOAD_SUCCEEDED':
      return {
        ...state,
        campaignUploadState: {
          lifeCycle: 'SUCCESS',
          ...action.payload,
        },
      };
    case 'SOIL_CAMPAIGN_FILE_UPLOAD_ERROR':
      return {
        ...state,
        campaignUploadState: {
          lifeCycle: 'ERROR',
          campaignId: action.payload?.response?.data?.campaignId,
          data: action.payload,
        },
      };
    case 'SOIL_CAMPAIGN_FILE_UPLOAD_CANCEL':
      return {
        ...state,
        campaignUploadState: {
          lifeCycle: 'CANCEL',
        },
      };
    case 'SOIL_CAMPAIGN_CREATION_STATE_RESET_REQUESTED':
      return {
        ...state,
        campaignUploadState: {
          lifeCycle: 'NONE',
        },
        campaignCreationState: {
          lifeCycle: 'NONE',
        },
      };
    case 'SOIL_CAMPAIGN_CREATION_REQUESTED':
      return {
        ...state,
        campaignCreationState: {
          lifeCycle: 'PENDING',
        },
      };
    case 'SOIL_CAMPAIGN_CREATION_SUCCEEDED':
      return {
        ...state,
        campaignCreationState: {
          lifeCycle: 'SUCCESS',
          campaignId: action.payload.campaignId,
          message: action.payload?.message,
        },
      };
    case 'SOIL_CAMPAIGN_CREATION_TIMEOUT':
      return {
        ...state,
        campaignCreationState: {
          lifeCycle: 'TIMEOUT',
          campaignId: action.payload.campaignId,
        },
      };
    case 'SOIL_CAMPAIGN_CREATION_ERROR':
      return {
        ...state,
        campaignCreationState: {
          lifeCycle: 'ERROR',
          campaignId: action.payload?.response?.data?.campaignId,
          data: action.payload,
        },
      };
    case 'NAVIGATE_TO_CAMPAIGN_BY_ID_REQUESTED':
      return {
        ...state,
        campaignUploadState: {
          lifeCycle: 'NONE',
        },
      };
    case 'SHOW_ARCHIVED_TOGGLED':
      return {
        ...state,
        shouldShowArchived: action.payload,
      };
    case 'CHANGE_CAMPAIGN_STATUS_REQUESTED':
      return {
        ...state,
        campaignChangeStatusState: {
          lifeCycle: 'PENDING',
        },
      };
    case 'CHANGE_CAMPAIGN_STATUS_SUCCEEDED':
      return {
        ...state,
        campaignChangeStatusState: {
          lifeCycle: 'SUCCESS',
        },
        campaigns: state.campaigns.map((campaign: any) =>
          campaign.id === action.payload.id
            ? { ...campaign, status: action.payload.status }
            : campaign,
        ),
      };
    case 'CHANGE_CAMPAIGN_NAME_AND_VENDOR_SUCCEEDED':
      return {
        ...state,
        campaignChangeStatusState: {
          lifeCycle: 'SUCCESS',
        },
        campaigns: state.campaigns.map((campaign: any) =>
          campaign.id === action.payload.id
            ? {
                ...campaign,
                name: action.payload.name,
                vendorId: action.payload.vendorId ?? campaign.vendorId,
                labId: getCampaignLab(action.payload),
                assessedStatus: action.payload.assessedStatus,
                vendorForCleanupId: action.payload.vendorForCleanupId,
                validCollectedPoints: action.payload.validCollectedPoints,
                notes: action.payload.notes,
              }
            : campaign,
        ),
      };
    case 'CHANGE_CAMPAIGN_STATUS_ERROR':
      return {
        ...state,
        campaignChangeStatusState: {
          lifeCycle: 'ERROR',
          data: action.payload,
        },
      };
    case 'CAMPAIGN_EXTERNAL_FILES_SUCCEEDED':
      return {
        ...state,
        campaignExternalFiles: action.payload,
      };
    case 'CAMPAIGN_FILTER_STATUS_UPDATE_REQUESTED':
      return {
        ...state,
        campaignFilter: {
          lifeCycle: 'PENDING',
          search: state.campaignFilter.search,
          status: action.payload,
        },
      };
    case 'CAMPAIGN_SEARCH_UPDATE_REQUESTED':
      return {
        ...state,
        campaignFilter: {
          lifeCycle: 'PENDING',
          search: action.payload,
          status: state.campaignFilter.status,
        },
      };
    case 'CAMPAIGN_FILTER_UPDATE_SUCCESS':
      state.campaignFilter.lifeCycle = 'SUCCESS';
      return state;
    case 'CAMPAIGN_CSV_GENERATION_MODAL_CANCEL':
      return {
        ...state,
        campaignCsvGeneration: {
          lifeCycle: 'CANCEL',
        },
      };
    case 'CAMPAIGN_CSV_GENERATION_MODAL_CONTINUE':
      return {
        ...state,
        campaignCsvGeneration: {
          lifeCycle: 'PROCEED',
        },
      };
    case 'CAMPAIGN_CSV_GENERATION_DONE':
      return {
        ...state,
        campaignCsvGeneration: {
          lifeCycle: 'DONE',
          updatedCampaign: action.payload,
        },
      };
    case 'CAMPAIGN_CSV_GENERATION_ERROR':
      return {
        ...state,
        campaignCsvGeneration: {
          lifeCycle: 'ERROR',
          errorMessage: action.payload.message,
        },
      };
    case 'CAMPAIGN_CSV_GENERATION_STATE_RESET':
      return {
        ...state,
        campaignCsvGeneration: {
          lifeCycle: 'NONE',
        },
      };
    case 'SEND_CAMPAIGN_TO_DEVERON_MODAL_CANCEL':
      return {
        ...state,
        sendCampaignToDeveron: {
          lifeCycle: 'CANCEL',
        },
      };
    case 'SEND_CAMPAIGN_TO_DEVERON_MODAL_CONTINUE':
      return {
        ...state,
        sendCampaignToDeveron: {
          lifeCycle: 'PROCEED',
        },
      };
    case 'SEND_CAMPAIGN_TO_DEVERON_DONE':
      return {
        ...state,
        sendCampaignToDeveron: {
          lifeCycle: 'DONE',
          updatedCampaign: action.payload,
        },
      };
    case 'SEND_CAMPAIGN_TO_DEVERON_ERROR':
      return {
        ...state,
        sendCampaignToDeveron: {
          lifeCycle: 'ERROR',
          errorMessage: action.payload.response.data.message,
        },
      };
    case 'SEND_CAMPAIGN_TO_DEVERON_STATE_RESET':
      return {
        ...state,
        sendCampaignToDeveron: {
          lifeCycle: 'NONE',
        },
      };
  }
  return state;
};
export default reducer;
