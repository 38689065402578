import { useAuth0 } from '@auth0/auth0-react';
import { Heading, Page, Grid } from 'grommet';
import { useNavigate } from 'react-router-dom';
import ViewCampaigns from '../../assets/agoro-graphics/view-campaigns.svg';
import ImageCard from '../../components/ImageCard';

const getCampaignCard = (buttonCallback: any) => {
  return (
    <ImageCard
      dataCy={'ss-campaign-card'}
      imageSrc={ViewCampaigns}
      title='Soil Sampling Campaigns'
      description={`View & Create your Campaigns`}
      buttonText='View Campaigns'
      buttonCallback={buttonCallback}
    />
  );
};
const Dashboard = () => {
  const { user } = useAuth0();
  const navigate = useNavigate();
  return (
    <Page margin='large' gap='large'>
      <Heading size='small' fill={true}>
        Hi {user.name || user.nickname}, what do you want to work on?
      </Heading>
      <Grid
        columns={{
          count: 5,
          size: 'auto',
        }}
        gap='small'
      >
        {getCampaignCard(() => {
          navigate('/campaigns');
        })}
      </Grid>
    </Page>
  );
};
export default Dashboard;
