import { API } from './api';
const listCampaigns = (
  salesforceId?: string,
  journeyId?: string,
  status?: string[],
) => {
  const params: { status?: string; salesforceId?: string; journeyId?: string } =
    {};
  if (salesforceId) {
    params.salesforceId = salesforceId;
  }
  if (journeyId) {
    params.journeyId = journeyId;
  }
  if (status) {
    params.status = status.join(',');
  }
  return API.get('/campaigns', params);
};
const uploadCloudAgFile = (payload: any) => {
  return API.upload('/campaigns/upload', payload);
};

const getProducer = (salesforceId: string) => {
  return API.get(`/growers/leads/${salesforceId}`, null, 'base');
};

const getJourney = (
  leadId: string,
  opportunityId: string,
  journeyId?: string,
) => {
  if (journeyId) {
    return API.get(`/producer/journey/${journeyId}`, null, 'base').then(
      (res) => {
        res.data.journeyId = res.data.id;
        return res;
      },
    );
  }
  return API.post('/producer', { leadId, opportunityId }, 'base');
};

const uploadCampaign = async (payload: any) => {
  if (!payload.archive?.type.match(/zip/)) throw Error('Incorrect file type.');

  const presignedData = await campaignService.getPresignedUrl(
    payload.campaignId,
  );
  return await campaignService
    .uploadFileToS3(presignedData.s3Url, payload.archive)
    .then(() => {
      return {
        campaignName: payload.campaignName,
        landType: payload.landType,
        fileName: presignedData.fileName,
        salesforceId: payload.salesforceId,
        journeyId: payload.journeyId,
      };
    })
    .catch((err) => {
      return err;
    });
};

const createCampaign = async (payload: any) => {
  return await API.post('/campaigns/create', {
    campaignName: payload.campaignName,
    landType: payload.landType,
    fileName: payload.fileName,
    salesforceId: payload.salesforceId,
    journeyId: payload.journeyId,
    notes: payload.notes,
    cycle: isNaN(payload.cycle) ? 1 : Number(payload.cycle),
  });
};

const getPresignedUrl = async (campaignId?: string) => {
  // GET request: presigned URL
  const response = await API.get(
    '/campaigns/signedUrl' + (campaignId ? `?campaignId=${campaignId}` : ''),
  );
  return {
    s3Url: response.data.s3Url.toString(),
    fileName: response.data.fileName.toString(),
  };
};

const uploadFileToS3 = async (s3Url: string, payload: any) => {
  // PUT request: upload file to S3
  return fetch(s3Url, {
    method: 'PUT',
    body: payload,
  });
};

const uploadBoundaryFile = (campaignId: string, payload: any) => {
  return API.upload(
    `/campaigns/${campaignId}/uploads/field-boundaries`,
    payload,
  );
};

const getCampaignById = (campaignId: string) => {
  return API.get(`/campaigns/${campaignId}`);
};

const updateCampaign = (campaignId: string, data: any) => {
  return API.patch(`/campaigns/${campaignId}`, data);
};

const updateSamplingPoint = (
  campaignId: string,
  pointId: string,
  data: any,
) => {
  return API.patch(`/campaigns/${campaignId}/sampling-points/${pointId}`, data);
};

const updateSamplingPointsInBulk = (campaignId: string, data: any) => {
  return API.patch(`/campaigns/${campaignId}/sampling-points/bulk`, data);
};

const removeSamplingPoint = (campaignId: string, pointId: string) => {
  return API.delete(`/campaigns/${campaignId}/sampling-points/${pointId}`);
};

const getCsvFileUrl = (
  campaignId: string,
  campaignName: string,
  vendorId: string,
) => {
  return API.post(
    `/campaigns/${campaignId}/generate-deveron-files?vendorId=${vendorId}`,
    {
      campaignName,
    },
  )
    .then((result) => result.data)
    .catch((e) => {
      if (e?.response?.status === 425) {
        throw new Error(
          'The campaign is still being processed, wait a few minutes and try again.',
        );
      }
      console.error('e', e);
    });
};

const getResultsCsvFileUrl = (
  campaignId: string,
  jobs: string[],
  fileName?: string,
) => {
  return API.post(`/campaigns/${campaignId}/generate-result-csv`, {
    jobs,
    fileName: fileName.replace(/\//g, '_'),
  })
    .then((result) => result.data)
    .catch((e) => {
      console.error('e', e);
    });
};

const saveFileFromUrl = (url: string) => {
  const link = document.createElement('a');
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const getPointLogs = (campaignId: string, pointId: string) => {
  return API.get(`/campaigns/${campaignId}/sampling-points/${pointId}/logs`);
};

const getVendors = () => {
  return API.get(`/vendors`);
};

const getLabs = () => {
  return API.get(`/labs`);
};

const addPointComment = (
  campaignId: string,
  pointId: string,
  comment: string,
) => {
  return API.post(`/campaigns/${campaignId}/sampling-points/${pointId}/logs`, {
    comment: comment,
  });
};

const sendCampaignToDeveron = (campaignId: string) => {
  return API.post(`/campaigns/${campaignId}/sync-deveron`, {}).then(
    (result) => result.data,
  );
};

const getExternalFilesList = (campaignId: string) => {
  return API.get(`/campaigns/${campaignId}/download`);
};

export const campaignService = {
  getProducer,
  getJourney,
  saveFileFromUrl,
  getCsvFileUrl,
  getResultsCsvFileUrl,
  uploadCloudAgFile,
  getPresignedUrl,
  uploadCampaign,
  createCampaign,
  uploadFileToS3,
  getExternalFilesList,
  uploadBoundaryFile,
  listCampaigns,
  getCampaignById,
  updateSamplingPoint,
  removeSamplingPoint,
  updateSamplingPointsInBulk,
  updateCampaign,
  getPointLogs,
  getVendors,
  getLabs,
  addPointComment,
  sendCampaignToDeveron,
};
