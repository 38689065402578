export interface Status {
  name: string;
  count: number;
  color: string;
  background: string;
}

export const STATUS_MAP: any = {
  'files-generated': {
    background: '#faffdc',
    color: '#aeb46a',
  },
  archived: {
    background: '#ffece0',
    color: '#eaad8c',
  },
  UNKNOWN: {
    background: '#e0e0e0',
    color: '#9d9d9d',
  },
  cancelled: {
    background: '#ffdddd',
    color: '#ec9393',
  },
  errored: {
    background: '#ffdddd',
    color: '#ec9393',
  },
  'in-review': {
    background: '#fffbd3',
    color: '#e0b64c',
  },
  new: {
    background: '#F8A90126',
    color: '#eec600',
  },
  planned: {
    background: '#EC946826',
    color: '#EC9468',
  },
  'in-progress': {
    background: '#A45CC326',
    color: '#A45CC3',
  },
  'lab-analysis': {
    background: '#5BB2E526',
    color: '#5BB2E5',
  },
  completed: {
    background: '#39B27B26',
    color: '#39B27B',
  },
};
