import mapboxgl from 'mapbox-gl';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import CampaignListPage from '../pages/campaign-list/CampaignList.page';
import CampaignUploadDetailsPage from '../pages/campaign-upload-details/CampaignUploadDetails.page';
import Dashboard from '../pages/dashboard';
import CampaignUploadDetailsPageV2 from '../pages/campaign-upload-details/CampaignUploadDetailsV2.page';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass =
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line
  // eslint-disable-next-line import/no-webpack-loader-syntax
  require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const RequireAuth = ({ children }: any) => {
  const isAuthenticated = useSelector((state: any) => state.user.accessToken);
  return isAuthenticated ? <>{children}</> : null;
};

const RoutingProvider = (): JSX.Element => {
  return (
    <Routes>
      <Route
        path='/'
        element={
          <RequireAuth>
            <Dashboard />
          </RequireAuth>
        }
      />
      <Route
        path='/campaigns/:campaignId'
        element={
          <RequireAuth>
            <CampaignUploadDetailsPage />
          </RequireAuth>
        }
      />
      <Route
        path='/campaigns'
        element={
          <RequireAuth>
            <CampaignListPage />
          </RequireAuth>
        }
      />
      <Route
        path='/producer/:campaignId'
        element={
          <RequireAuth>
            <CampaignUploadDetailsPageV2 />
          </RequireAuth>
        }
      />
    </Routes>
  );
};

export default React.memo(RoutingProvider);
