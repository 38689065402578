import { campaignService } from '../../service/campaign.service';

export const getListCampaignsAction: any =
  (salesforceId?: string, journeyId?: string) => (dispatch: any) => {
    dispatch({
      type:
        salesforceId || journeyId
          ? 'LIST_PRODUCER_CAMPAIGNS_REQUESTED'
          : 'LIST_CAMPAIGNS_REQUESTED',
    });
    return campaignService
      .listCampaigns(salesforceId, journeyId)
      .then((res) => {
        dispatch({
          type: 'LIST_CAMPAIGNS_SUCCEEDED',
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: 'LIST_CAMPAIGNS_ERROR',
          payload: err.response,
        });
      });
  };

export const getVendorsAction: any = () => (dispatch: any) => {
  dispatch({
    type: 'GET_VENDORS_REQUESTED',
  });
  return campaignService
    .getVendors()
    .then((res) => {
      const vendorNameDictionary: Record<string, string> = {};
      res.data.forEach((vendor: any) => {
        vendorNameDictionary[vendor.id as string] = vendor.name;
      });
      dispatch({
        type: 'GET_VENDORS_SUCCEEDED',
        payload: vendorNameDictionary,
      });
    })
    .catch((err) => {
      dispatch({
        type: 'GET_VENDORS_ERROR',
        payload: err.response,
      });
    });
};

export const getLabsAction: any = () => (dispatch: any) => {
  dispatch({
    type: 'GET_LABS_REQUESTED',
  });
  return campaignService
    .getLabs()
    .then((res) => {
      const labNameDictionary: Record<string, string> = {};
      res.data.forEach((vendor: any) => {
        labNameDictionary[vendor.id as string] = vendor.name;
      });
      dispatch({
        type: 'GET_LABS_SUCCEEDED',
        payload: labNameDictionary,
      });
    })
    .catch((err) => {
      dispatch({
        type: 'GET_LABS_ERROR',
        payload: err.response,
      });
    });
};

export const getProducerAction: any =
  (salesforceId?: string) => (dispatch: any) => {
    dispatch({
      type: 'GET_PRODUCER_REQUESTED',
    });
    return campaignService
      .getProducer(salesforceId)
      .then((res) => {
        dispatch({
          type: 'GET_PRODUCER_SUCCEEDED',
          payload: res.data,
        });
      })
      .catch(() => {
        dispatch({
          type: 'GET_PRODUCER_ERROR',
        });
      });
  };

export const getJourneyAction: any =
  (leadId: string, opportunityId: string, journeyId?: string) =>
  (dispatch: any) => {
    dispatch({
      type: 'GET_JOURNEY_REQUESTED',
    });
    return campaignService
      .getJourney(leadId, opportunityId, journeyId)
      .then((res) => {
        dispatch({
          type: 'GET_JOURNEY_SUCCEEDED',
          payload: res.data,
        });
      })
      .catch(() => {
        dispatch({
          type: 'GET_JOURNEY_ERROR',
        });
      });
  };

export const cancelUploadFileStateAction = () => {
  return {
    type: 'SOIL_CAMPAIGN_FILE_UPLOAD_CANCEL',
  };
};

export const getSendFileAction: any =
  (
    campaignType: string,
    file: any,
    salesforceId?: string,
    journeyId?: string,
    campaignId?: string,
  ) =>
  (dispatch: any) => {
    const payload = {
      campaignId,
      campaignName: file.name.split('.')[0],
      landType: campaignType,
      archive: file,
      salesforceId: salesforceId,
      journeyId: journeyId,
    };
    dispatch({
      type: 'SOIL_CAMPAIGN_FILE_UPLOAD_REQUESTED',
      payload: {
        archive: file,
        landType: campaignType,
        campaignName: file.name.split('.')[0],
      },
    });
    return campaignService
      .uploadCampaign(payload)
      .then((result) => {
        dispatch({
          type: 'SOIL_CAMPAIGN_FILE_UPLOAD_SUCCEEDED',
          payload: result,
        });
      })
      .catch((err) => {
        console.log(err.message);
        console.error('File upload error', err);
        dispatch({
          type: 'SOIL_CAMPAIGN_FILE_UPLOAD_ERROR',
          payload: {
            err,
          },
        });
      });
  };

export const getResetCampaignCreationStateAction = () => {
  return {
    type: 'SOIL_CAMPAIGN_CREATION_STATE_RESET_REQUESTED',
  };
};

export const getCreateCampaignAction: any =
  (payload: {
    campaignName: string;
    landType: string;
    fileName: string;
    salesforceId: string;
    journeyId: string;
    notes: string;
    cycle: number;
  }) =>
  (dispatch: any) => {
    dispatch({
      type: 'SOIL_CAMPAIGN_CREATION_REQUESTED',
      payload,
    });
    return campaignService
      .createCampaign(payload)
      .then((result) => {
        dispatch({
          type: 'SOIL_CAMPAIGN_CREATION_SUCCEEDED',
          payload: result.data,
        });
      })
      .catch((err) => {
        if (err.response?.status === 503) {
          dispatch({
            type: 'SOIL_CAMPAIGN_CREATION_TIMEOUT',
            payload: {
              ...err,
            },
          });
        } else {
          console.error('File upload error', err);
          dispatch({
            type: 'SOIL_CAMPAIGN_CREATION_ERROR',
            payload: {
              ...err,
            },
          });
        }
      });
  };

export const getChangeCampaignStatusAction: any =
  (campaignId: string, status: string) => (dispatch: any) => {
    dispatch({
      type: 'CHANGE_CAMPAIGN_STATUS_REQUESTED',
    });
    return campaignService
      .updateCampaign(campaignId, { status: status })
      .then((result) => {
        dispatch({
          type: 'CHANGE_CAMPAIGN_STATUS_SUCCEEDED',
          payload: result.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: 'CHANGE_CAMPAIGN_STATUS_ERROR',
          payload: {
            ...err,
          },
        });
      });
  };

export const getListExternalFilesAction: any =
  (campaignId: string) => (dispatch: any) => {
    dispatch({
      type: 'CAMPAIGN_EXTERNAL_FILES_REQUESTED',
    });
    return campaignService
      .getExternalFilesList(campaignId)
      .then((result) => {
        dispatch({
          type: 'CAMPAIGN_EXTERNAL_FILES_SUCCEEDED',
          payload: result.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: 'CAMPAIGN_EXTERNAL_FILES_ERROR',
          payload: {
            ...err,
          },
        });
      });
  };

export const getChangeCampaignNameAndVendorAction: any =
  (campaignId: string, campaignData: any) => (dispatch: any) => {
    dispatch({
      type: 'CHANGE_CAMPAIGN_NAME_AND_VENDOR_REQUESTED',
    });
    return campaignService
      .updateCampaign(campaignId, campaignData)
      .then((result) => {
        dispatch({
          type: 'CHANGE_CAMPAIGN_NAME_AND_VENDOR_SUCCEEDED',
          payload: result.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: 'CHANGE_CAMPAIGN_NAME_AND_VENDOR_ERROR',
          payload: {
            ...err,
          },
        });
      });
  };

export const campaignCsvGenerationResetAction = () => {
  return {
    type: 'CAMPAIGN_CSV_GENERATION_STATE_RESET',
  };
};

export const campaignCsvGenerationModalCancelAction = () => {
  return {
    type: 'CAMPAIGN_CSV_GENERATION_MODAL_CANCEL',
  };
};

export const campaignCsvGenerationModalProceedAction: any =
  (campaignId: string, campaignName: string, vendorId: string) =>
  (dispatch: any) => {
    dispatch({ type: 'CAMPAIGN_CSV_GENERATION_MODAL_CONTINUE' });

    return campaignService
      .getCsvFileUrl(campaignId, campaignName, vendorId)
      .then((result: any) => {
        campaignService.saveFileFromUrl(result.zipUrl);
        dispatch(getListExternalFilesAction(campaignId));
        dispatch(campaignCsvGenerationDoneAction(result.campaign));
      })
      .catch((err) => {
        dispatch({
          type: 'CAMPAIGN_CSV_GENERATION_ERROR',
          payload: {
            message: err.message,
            ...err,
          },
        });
      });
  };

export const campaignCsvGenerationDoneAction = (campaign: any) => {
  return {
    type: 'CAMPAIGN_CSV_GENERATION_DONE',
    payload: campaign,
  };
};

export const downloadGeneratedFilesZipAction: any = (zipUrl: string) => {
  return campaignService.saveFileFromUrl(zipUrl);
};

export const sendCampaignToDeveronProceedAction: any =
  (campaignId: string) => (dispatch: any) => {
    dispatch({ type: 'SEND_CAMPAIGN_TO_DEVERON_MODAL_CONTINUE' });

    return campaignService
      .sendCampaignToDeveron(campaignId)
      .then((result: any) => {
        dispatch(sendCampaignToDeveronDoneAction(result));
      })
      .catch((err) => {
        dispatch({
          type: 'SEND_CAMPAIGN_TO_DEVERON_ERROR',
          payload: {
            ...err,
          },
        });
      });
  };

export const sendCampaignToDeveronDoneAction = (campaign: any) => {
  return {
    type: 'SEND_CAMPAIGN_TO_DEVERON_DONE',
    payload: campaign,
  };
};

export const sendCampaignToDeveronCancelAction = () => {
  return {
    type: 'SEND_CAMPAIGN_TO_DEVERON_MODAL_CANCEL',
  };
};

export const sendCampaignToDeveronResetAction = () => {
  return {
    type: 'SEND_CAMPAIGN_TO_DEVERON_STATE_RESET',
  };
};
