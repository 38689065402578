import { configureStore } from '@reduxjs/toolkit';
import soilCampaignReducer from '../reducers/soil-campaign-reducer';
import userReducer from '../reducers/user-reducer';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
const logger = createLogger({
  collapsed: true,
});
const store = configureStore({
  reducer: {
    user: userReducer,
    soilCampaign: soilCampaignReducer,
  },

  middleware: [thunk, logger],
});
export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
