import axios from 'axios';
import store from '../redux/store';
import { getAccessTokenAction } from '../redux/actions/user-actions';

const SOIL_SAMPLING_URL = process.env.REACT_APP_SOIL_SAMPLING_API_ENDPOINT;
const BASE_URL = process.env.REACT_APP_BASE_API_ENDPOINT;

const getAccessToken = () => {
  const state = store.getState();
  return state.user.accessToken;
};

const axiosApiInstance = axios.create();

// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
  async (config) => {
    config.headers = {
      Authorization: `Bearer ${getAccessToken()}`,
    };
    return config;
  },
  (error) => Promise.reject(error),
);

// Response interceptor for API calls
axiosApiInstance.interceptors.response.use(
  (response) => response,
  async function (error) {
    const state = store.getState();
    const getAccessTokenFn = state.user.getAccessTokenFn;

    const originalRequest = error.config;
    if (
      (error.response.status === 403 || error.response.status === 401) &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      await store.dispatch(getAccessTokenAction(getAccessTokenFn));
      axios.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${getAccessToken()}`;
      return axiosApiInstance(originalRequest);
    }
    return Promise.reject(error);
  },
);

const upload = async (url: string, payload: any, onUploadProgress?: any) => {
  const form = Object.keys(payload).reduce((formData, key) => {
    formData.append(key, payload[key]);
    return formData;
  }, new FormData());
  return axiosApiInstance({
    method: 'post',
    url: `${SOIL_SAMPLING_URL}${url}`,
    data: form,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress: (data) => {
      const percent = Math.round((100 * data.loaded) / data.total);
      console.log(`Uploading ${percent}%`);
      if (onUploadProgress) {
        onUploadProgress(percent);
      }
    },
  });
};

const get = async (url: string, params?: any, service?: string) => {
  const baseUrl = service === 'base' ? BASE_URL : SOIL_SAMPLING_URL;
  return axiosApiInstance.get(`${baseUrl}${url}`, { params });
};

const patch = async (url: string, bodyData: any) => {
  return axiosApiInstance.patch(`${SOIL_SAMPLING_URL}${url}`, bodyData);
};

const post = async (url: string, bodyData: any, service?: string) => {
  const baseUrl = service === 'base' ? BASE_URL : SOIL_SAMPLING_URL;
  return axiosApiInstance.post(`${baseUrl}${url}`, bodyData);
};

const deleteMethod = async (url: string) => {
  return axiosApiInstance.delete(`${SOIL_SAMPLING_URL}${url}`);
};

export const API = {
  upload,
  post,
  get,
  patch,
  delete: deleteMethod,
};
