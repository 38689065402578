import { Box } from 'grommet';

const PointsCounterComponent = (props: {
  adaptViewOpened: boolean;
  pointsCounterData: any;
}) => {
  return (
    <Box pad={'small'} style={{ alignSelf: 'center', minWidth: '170px' }}>
      {props.pointsCounterData.map((item: any) => {
        return (
          <Box
            key={item.label}
            direction={'row'}
            style={{
              display: 'flex',
              alignItems: 'center',
              fontSize: '14px',
              lineHeight: '16px',
              height: '24px',
            }}
          >
            {item.icon}
            <p style={{ fontWeight: '400', width: '90px' }}>{item.label}</p>
            <div style={{ textAlign: 'right' }}>
              <b>{item.currentPoints}</b>
            </div>
          </Box>
        );
      })}
    </Box>
  );
};

export default PointsCounterComponent;
