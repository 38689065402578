import { Box, CheckBox, TextInput } from 'grommet';
import { useDispatch, useSelector } from 'react-redux';
import { Search } from 'grommet-icons';
import { Status, STATUS_MAP } from '../utils/status';
import { useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import {
  CampaignStatusesDisplay,
  CampaignStatusesTooltipDisplay,
  Statuses,
} from '../../campaign-upload-details/utils/enums';
import * as React from 'react';
import { StyledTooltip } from '../../../components/common/StyledTooltip';

const getColor = (
  statusMap: any,
  status: string,
  campaignFilterStatusSelected: string,
) => {
  if (!statusMap[status]) {
    return '#fff';
  }
  if (
    !campaignFilterStatusSelected ||
    campaignFilterStatusSelected === status
  ) {
    return statusMap[status].color;
  }
  return '#CCC';
};

const getBackgroundColor = (
  statusMap: any,
  status: string,
  campaignFilterStatusSelected: string,
) => {
  if (!statusMap[status]) {
    return '#CCC';
  }
  if (
    !campaignFilterStatusSelected ||
    campaignFilterStatusSelected === status
  ) {
    return statusMap[status].background;
  }
  return '#CCCCCC26';
};

const CampaignListHeader = () => {
  const dispatch = useDispatch();

  const { campaigns, shouldShowArchived, campaignFilter } = useSelector(
    (state: any) => {
      return {
        campaigns: state.soilCampaign.campaigns,
        shouldShowArchived: state.soilCampaign.shouldShowArchived,
        campaignFilter: state.soilCampaign.campaignFilter,
      };
    },
  );
  const [statuses, setStatuses] = useState<Status[]>([]);
  const campaignFilterStatusSelected: string = campaignFilter.status;

  const onToggleShowArchived = (isChecked: boolean) => {
    dispatch({
      type: 'SHOW_ARCHIVED_TOGGLED',
      payload: isChecked,
    });
  };

  useEffect(() => {
    if (!campaigns || campaigns.length === 0) return;

    const statusCountInfo: Record<string, number> = {
      'in-review': 0,
      'files-generated': 0,
      new: 0,
      planned: 0,
      'in-progress': 0,
      'lab-analysis': 0,
      completed: 0,
      Errored: 0,
      UNKNOWN: 0,
      cancelled: 0,
      Archived: 0,
    };
    const newStatuses = [];
    for (let campaign of campaigns) {
      if (!statusCountInfo[campaign.status]) {
        statusCountInfo[campaign.status] = 0;
      }
      statusCountInfo[campaign.status]++;
    }

    if (!shouldShowArchived) delete statusCountInfo[Statuses['archived']];
    for (let status in statusCountInfo) {
      if (statusCountInfo[status] === 0) continue;
      newStatuses.push({
        name: status,
        count: statusCountInfo[status],
        color: getColor(STATUS_MAP, status, campaignFilterStatusSelected),
        background: getBackgroundColor(
          STATUS_MAP,
          status,
          campaignFilterStatusSelected,
        ),
      });
    }
    setStatuses(newStatuses);
  }, [
    campaigns,
    setStatuses,
    shouldShowArchived,
    campaignFilter,
    campaignFilterStatusSelected,
  ]);

  const debounceSearch = useMemo(() => {
    return debounce((event) => {
      dispatch({
        type: 'CAMPAIGN_SEARCH_UPDATE_REQUESTED',
        payload: event.target.value,
      });
    }, 800);
  }, [dispatch]);

  useEffect(() => {
    return () => debounceSearch.cancel();
  });

  return (
    <Box
      direction='row'
      pad={'24px'}
      style={{
        background: '#FFFFFF',
        border: '1px solid #CCCCCC',
        borderRadius: '5px',
        marginTop: '8px',
        height: 'fit-content',
        minHeight: '96px',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        data-cy={'campaign-status-filter'}
        direction={'row'}
        wrap={true}
        style={{
          width: '-webkit-fill-available',
          gap: '8px 0',
          margin: `8px 0 8px 0`,
        }}
        margin={'0 8px 0 8px'}
        justify={'center'}
      >
        {statuses.map((i) => (
          <StyledTooltip
            key={i.name}
            label={
              CampaignStatusesTooltipDisplay[
                i.name as keyof typeof CampaignStatusesTooltipDisplay
              ]
            }
            disabled={
              !CampaignStatusesTooltipDisplay[
                i.name as keyof typeof CampaignStatusesTooltipDisplay
              ]
            }
          >
            <Box
              data-cy={`${i.name}-status-filter`}
              direction={'row'}
              onClick={() => {
                const selectedStatus =
                  i.name === campaignFilter.status ? undefined : i.name;
                dispatch({
                  type: 'CAMPAIGN_FILTER_STATUS_UPDATE_REQUESTED',
                  payload: selectedStatus,
                });
              }}
              key={i.name}
              style={{
                borderRadius: '2px',
                marginRight: '7px',
                background: i.background,
                color: i.color,
                paddingLeft: '8px',
                paddingRight: '8px',
                paddingTop: '4px',
                paddingBottom: '4px',
                height: '32px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontWeight: 700,
                fontSize: '16px',
                lineHeight: '16px',
              }}
            >
              <span
                style={{
                  marginRight: '5px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: '#282828',
                  paddingLeft: '4px',
                  paddingRight: '4px',
                  height: '24px',
                  background: '#F2F2F2',
                  borderRadius: '5px',
                }}
              >
                {i.count}
              </span>
              {CampaignStatusesDisplay[
                i.name as keyof typeof CampaignStatusesDisplay
              ] ?? i.name}
            </Box>
          </StyledTooltip>
        ))}
      </Box>
      <Box width={'292px'} justify={'center'}>
        <TextInput
          icon={<Search style={{ height: '18px', width: '18px' }} />}
          reverse={true}
          placeholder={'Search Here'}
          onChange={debounceSearch}
          style={{
            background: '#FFF',
            border: '1px solid #808080',
            height: '40px',
            fontSize: '1rem',
          }}
        />
        <Box margin={{ top: '1rem' }} direction={'row'}>
          <CheckBox
            data-cy={'show-archived-check'}
            style={{ marginRight: '1rem', paddingRight: '1rem' }}
            checked={shouldShowArchived}
            label={
              <Box
                style={{
                  marginTop: '0.2rem',
                  fontSize: '13px',
                }}
              >
                Show archived
              </Box>
            }
            onChange={(event) => onToggleShowArchived(event.target.checked)}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default CampaignListHeader;
