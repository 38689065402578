import { Box, Button, DataTable, Text } from 'grommet';
import { Download } from 'grommet-icons';
import { format } from 'date-fns';
import showModal from '../../../components/modal/modal';
import UploadCampaignModal from '../../../components/campaign-upload/UploadCampaignModal';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef } from 'react';
import {
  cancelUploadFileStateAction,
  getListExternalFilesAction,
  getResetCampaignCreationStateAction,
} from '../../../redux/actions/soil-campaign-actions';
import { Statuses } from '../utils/enums';
import { campaignService } from '../../../service/campaign.service';

const ExternalFilesList = ({
  data,
  status,
  errors,
  campaignId,
  salesforceId,
  journeyId,
  getCampaignData,
}: any) => {
  const uploadModal = useRef<(reason: string) => void>();
  const { uploadState, campaignCreationState } = useSelector((state: any) => {
    return {
      uploadState: state.soilCampaign.campaignUploadState,
      campaignCreationState: state.soilCampaign.campaignCreationState,
    };
  });
  const dispatch = useDispatch();

  function convertErrorCodeToMessage(error: string) {
    const splitError = error.toLowerCase().split('_');
    for (let i = 0; i < splitError.length; i++) {
      if (i === splitError.length - 1) {
        splitError[i] = splitError[i].toUpperCase();
        continue;
      }
      splitError[i] =
        splitError[i].charAt(0).toUpperCase() + splitError[i].substring(1);
    }

    return splitError.join(' ');
  }

  function handleFileDownload(fileName: string, modifiedTime: string) {
    campaignService.getExternalFilesList(campaignId).then((res) => {
      const files = res.data;
      const fileToDownload = files.find(
        (file: any) =>
          file.fileName === fileName && file.modifiedTime === modifiedTime,
      );
      campaignService.saveFileFromUrl(fileToDownload.downloadUrl);
    });
  }

  const errorMapping: { [key: string]: string } = {
    NO_JOBS: 'Missing Jobs',
    JOBS_WITHOUT_BOUNDARIES: 'Missing Boundaries',
    NO_STRATA: 'Missing Strata',
  };

  useEffect(() => {
    if (['SUCCESS', 'ERROR'].indexOf(campaignCreationState.lifeCycle) !== -1) {
      uploadModal.current('');
      getCampaignData();
      dispatch(getListExternalFilesAction(campaignId));
      dispatch(getResetCampaignCreationStateAction());
    }
  }, [campaignCreationState, dispatch, campaignId, getCampaignData]);

  useEffect(() => {
    if (uploadState.lifeCycle === 'CANCEL') {
      uploadModal.current('');
      dispatch(getResetCampaignCreationStateAction());
    }
  }, [uploadState.lifeCycle, dispatch]);

  return (
    <Box pad={'small'}>
      {status === Statuses['errored'] && (
        <Box
          margin={{ left: '16px' }}
          direction={'row'}
          justify={'between'}
          align={'center'}
        >
          <Text>
            This file contains errors:{' '}
            <b>
              {errors
                ?.map(
                  (error: string) =>
                    errorMapping[error] ?? convertErrorCodeToMessage(error),
                )
                .join('; ') ?? '--'}
            </b>
          </Text>
          <Button
            className={'generate-files-button'}
            style={{ minWidth: '160px', textAlign: 'center' }}
            alignSelf={'end'}
            label={'Reupload file'}
            onClick={() => {
              uploadModal.current = showModal(
                'Drop your file to update the campaign',
                <UploadCampaignModal
                  closeModal={() => dispatch(cancelUploadFileStateAction())}
                  campaignId={campaignId}
                  salesforceId={salesforceId}
                  journeyId={journeyId}
                  uploadButtonLabel={'Upload Campaign'}
                />,
                null,
                null,
                false,
              );
            }}
          />
        </Box>
      )}
      <DataTable
        data-cy={'files-table'}
        columns={[
          {
            property: 'fileName',
            header: <Text>File Name</Text>,
            primary: true,
            sortable: true,
            render: (rowData) => (
              <Text
                style={{
                  textOverflow: 'ellipsis',
                  maxWidth: '50vw',
                  display: 'block',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                }}
              >
                {rowData.fileName || '-'}
              </Text>
            ),
          },
          {
            property: 'creationTime',
            header: <Text>Creation Time</Text>,
            sortable: true,
            render: (rowData) => (
              <Text>
                Created at{' '}
                {format(
                  new Date(rowData.modifiedTime),
                  'KK:mmbbb MMM dd, yyyy',
                ) || '-'}
              </Text>
            ),
          },
          {
            property: 'action',
            header: <Text>Download</Text>,
            sortable: true,
            plain: true,
            render: (rowData) => (
              <Box
                align='left'
                width='40px'
                pad={'1rem 0.5rem 1rem 0.5rem'}
                onClick={() =>
                  handleFileDownload(rowData.fileName, rowData.modifiedTime)
                }
              >
                <Download />
              </Box>
            ),
          },
        ]}
        data={data}
        background={{
          body: ['#fff', '#F3F6F9'],
        }}
        pad={{
          header: '1rem 0.5rem 1rem 0.5rem',
          body: '1rem 0.5rem 1rem 0.5rem',
        }}
      />
    </Box>
  );
};

export default ExternalFilesList;
