import { normalizeColor, deepMerge } from 'grommet/utils';
import { grommet } from 'grommet';

import { css } from 'styled-components';

const checkboxCheckStyle = css``;

export const appTheme: any = {
  global: {
    colors: {
      brand: {
        dark: '#ffe137',
        light: '#FFE137',
      },
      'brand-hover': {
        dark: '#E7CC33',
        light: '#E7CC33',
      },
      background: {
        dark: '#282828',
        light: '#FFFFFF',
      },
      'background-back': {
        dark: '282828',
        light: '#FFFFFF',
      },
      'background-front': {
        dark: '#282828',
        light: '#FFFFFF',
      },
      'background-contrast': {
        dark: '#282828',
        light: '#11111111',
      },
      text: {
        dark: '#EEEEEE',
        light: '#282828',
      },
      'text-strong': {
        dark: '#FFFFFF',
        light: '#282828',
      },
      'text-weak': {
        dark: '#CCCCCC',
        light: '#444444',
      },
      'text-xweak': {
        dark: '#999999',
        light: '#666666',
      },
      border: {
        dark: '#444444',
        light: '#CCCCCC',
      },
      control: 'brand',
      'active-background': 'background-contrast',
      'active-text': 'text-strong',
      'selected-background': 'brand',
      'selected-text': 'text-strong',
      'status-critical': '#FF4040',
      'status-warning': '#FFAA15',
      'status-ok': '#00C781',
      'status-unknown': '#CCCCCC',
      'status-disabled': '#CCCCCC',
      'graph-0': 'brand',
      'graph-1': 'status-warning',
      k20: '#f2f2f2',
    },
    font: {
      family: 'Noto Sans',
      size: '12px',
      height: '16px',
      maxWidth: '192px',
    },
    active: {
      background: 'active-background',
      color: 'active-text',
    },
    hover: {
      background: 'active-background',
      color: 'active-text',
    },
    selected: {
      background: 'selected-background',
      color: 'selected-text',
    },
    focus: {
      border: {
        color: 'none',
      },
    },
    control: {
      border: {
        radius: '0px',
      },
    },
    drop: {
      border: {
        radius: '0px',
      },
    },
    borderSize: {
      xsmall: '1px',
      small: '2px',
      medium: '3px',
      large: '8px',
      xlarge: '16px',
    },
    breakpoints: {
      small: {
        value: 512,
        borderSize: {
          xsmall: '1px',
          small: '2px',
          medium: '3px',
          large: '4px',
          xlarge: '8px',
        },
        edgeSize: {
          none: '0px',
          hair: '1px',
          xxsmall: '2px',
          xsmall: '2px',
          small: '4px',
          medium: '8px',
          large: '16px',
          xlarge: '32px',
        },
        size: {
          xxsmall: '16px',
          xsmall: '32px',
          small: '64px',
          medium: '128px',
          large: '256px',
          xlarge: '512px',
          full: '100%',
        },
      },
      medium: {
        value: 1024,
      },
      large: {},
    },
    edgeSize: {
      none: '0px',
      hair: '1px',
      xxsmall: '2px',
      xsmall: '4px',
      small: '8px',
      medium: '16px',
      large: '32px',
      xlarge: '64px',
      responsiveBreakpoint: 'small',
    },
    input: {
      padding: '8px',
      weight: 600,
    },
    spacing: '16px',
    size: {
      xxsmall: '32px',
      xsmall: '64px',
      small: '128px',
      medium: '256px',
      large: '512px',
      xlarge: '768px',
      xxlarge: '1024px',
      full: '100%',
    },
  },
  select: {
    icons: {
      color: '#326FAE',
    },

    control: {
      extend: 'border: 2px solid #326FAE; border-radius: 1px;background: #fff',
      open: {
        border: 'solid 1px #326FAE',
      },
    },
  },
  chart: {},
  diagram: {
    line: {},
  },
  meter: {},
  tip: {
    content: {
      background: {
        color: 'background',
      },
      elevation: 'none',
      round: false,
    },
  },
  accordion: {
    icons: {
      color: '#282828',
    },
  },
  button: {
    border: {
      width: '1px',
      radius: '0px',
    },
    padding: {
      vertical: '8px',
      horizontal: '24px',
    },
    extend: ({ primary, disabled, theme }: any) => css`
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      padding: 8px 24px;
      min-height: 40px;

      &:hover {
        box-shadow: none;
        ${!disabled && `background-color: ${normalizeColor('k20', theme)}`}
      }

      &:active {
        box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.3) !important;
      }

      ${primary &&
      css`
        &:hover {
          ${!disabled &&
          `background-color: ${normalizeColor('brand-hover', theme)}`}
        }
      `}
    `,
  },
  checkBox: {
    border: {
      color: '#244F7C',
      width: '1px',
    },
    hover: 'none',
    check: {
      radius: '2px',
      thickness: '3px',
    },
    toggle: {
      background: { light: 'toggle-accent' },
      color: {
        light: 'white',
      },
      size: '40px',
      knob: {
        extend: `
          top: -2px;
          border: solid 1px #244F7C;
          box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.12),
           0px 2px 2px 0px rgba(0,0,0,0.24);
        `,
      },
      extend: ({ checked }: any) => `
        height: 20px;
        background: #F3F6F9;
        border: 1px solid #FFFFFF;

        box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.12), inset 0px 2px 2px rgba(0, 0, 0, 0.08), inset 0px 1px 5px rgba(0, 0, 0, 0.24);
        border-radius: 12px;
        ${checked && checkboxCheckStyle}
     `,
    },
    size: '22px',
    color: '#244F7C',
  },
  radioButton: {
    size: '18px',
    border: {
      width: '1px',
      color: '#004F29',
    },
    check: {
      color: '#004F29',
    },
    container: {
      extend: css`
        svg {
          circle {
            r: 8;
          }
        }
      `,
    },
    hover: { border: { color: 'none' } },
    color: '#004F29',
  },
  tab: {
    color: '#515564',
    active: {
      color: '#191408',
    },
    border: {
      side: 'bottom',
      color: 'white',
      active: {
        color: '#244F7C',
      },
    },
    pad: 'small',
    margin: {
      horizontal: 'none',
    },
  },
  formField: {
    border: {
      color: 'border',
      error: {
        color: {
          dark: 'white',
          light: 'status-critical',
        },
      },
      position: 'outer',
      side: 'bottom',
    },
    content: {
      pad: 'small',
    },
    disabled: {
      background: {
        color: 'status-disabled',
        opacity: 'medium',
      },
    },
    error: {
      color: 'status-critical',
      margin: {
        vertical: 'xsmall',
        horizontal: 'small',
      },
    },
    help: {
      color: 'dark-3',
      margin: {
        start: 'small',
      },
    },
    info: {
      color: 'text-xweak',
      margin: {
        vertical: 'xsmall',
        horizontal: 'small',
      },
    },
    label: {
      margin: {
        vertical: 'xsmall',
        horizontal: 'small',
      },
    },
    margin: {
      bottom: 'large',
    },
    round: '0px',
  },
  calendar: {
    heading: { level: '5' },
    extend: '',
    day: {
      extend: 'color:#191408',
    },
    small: {
      fontSize: '9.333333333333334px',
      lineHeight: 1.375,
      daySize: '18.29px',
    },
    medium: {
      fontSize: '12px',
      lineHeight: 1.45,
      daySize: '36.57px',
    },
    large: {
      fontSize: '20px',
      lineHeight: 1.11,
      daySize: '73.14px',
    },
    icons: {
      small: {
        previous: 'size:25px',
      },
    },
  },
  clock: {
    analog: {
      hour: {
        width: '5px',
        size: '16px',
      },
      minute: {
        width: '3px',
        size: '8px',
      },
      second: {
        width: '2px',
        size: '6px',
      },
      size: {
        small: '48px',
        medium: '64px',
        large: '96px',
        xlarge: '144px',
        huge: '192px',
      },
    },
    digital: {
      text: {
        xsmall: {
          size: '6.666666666666667px',
          height: 1.5,
        },
        small: {
          size: '9.333333333333334px',
          height: 1.43,
        },
        medium: {
          size: '12px',
          height: 1.375,
        },
        large: {
          size: '14.666666666666666px',
          height: 1.167,
        },
        xlarge: {
          size: '17.333333333333332px',
          height: 1.1875,
        },
        xxlarge: {
          size: '22.666666666666664px',
          height: 1.125,
        },
      },
    },
  },
  heading: {
    level: {
      1: {
        small: {
          size: '23px',
          height: '27px',
          maxWidth: '363px',
        },
        medium: {
          size: '33px',
          height: '37px',
          maxWidth: '533px',
        },
        large: {
          size: '55px',
          height: '59px',
          maxWidth: '875px',
        },
        xlarge: {
          size: '76px',
          height: '80px',
          maxWidth: '1216px',
        },
      },
      2: {
        small: {
          size: '20px',
          height: '24px',
          maxWidth: '320px',
        },
        medium: {
          size: '28px',
          height: '32px',
          maxWidth: '448px',
        },
        large: {
          size: '36px',
          height: '40px',
          maxWidth: '576px',
        },
        xlarge: {
          size: '44px',
          height: '48px',
          maxWidth: '704px',
        },
      },
      3: {
        small: {
          size: '17px',
          height: '21px',
          maxWidth: '277px',
        },
        medium: {
          size: '23px',
          height: '27px',
          maxWidth: '363px',
        },
        large: {
          size: '28px',
          height: '32px',
          maxWidth: '448px',
        },
        xlarge: {
          size: '33px',
          height: '37px',
          maxWidth: '533px',
        },
      },
      4: {
        small: {
          size: '15px',
          height: '19px',
          maxWidth: '235px',
        },
        medium: {
          size: '17px',
          height: '21px',
          maxWidth: '277px',
        },
        large: {
          size: '20px',
          height: '24px',
          maxWidth: '320px',
        },
        xlarge: {
          size: '23px',
          height: '27px',
          maxWidth: '363px',
        },
      },
      5: {
        small: {
          size: '11px',
          height: '15px',
          maxWidth: '171px',
        },
        medium: {
          size: '11px',
          height: '15px',
          maxWidth: '171px',
        },
        large: {
          size: '11px',
          height: '15px',
          maxWidth: '171px',
        },
        xlarge: {
          size: '11px',
          height: '15px',
          maxWidth: '171px',
        },
      },
      6: {
        small: {
          size: '9px',
          height: '13px',
          maxWidth: '149px',
        },
        medium: {
          size: '9px',
          height: '13px',
          maxWidth: '149px',
        },
        large: {
          size: '9px',
          height: '13px',
          maxWidth: '149px',
        },
        xlarge: {
          size: '9px',
          height: '13px',
          maxWidth: '149px',
        },
      },
    },
  },
  paragraph: {
    small: {
      size: '11px',
      height: '15px',
      maxWidth: '171px',
    },
    medium: {
      size: '12px',
      height: '16px',
      maxWidth: '192px',
    },
    large: {
      size: '15px',
      height: '19px',
      maxWidth: '235px',
    },
    xlarge: {
      size: '17px',
      height: '21px',
      maxWidth: '277px',
    },
    xxlarge: {
      size: '23px',
      height: '27px',
      maxWidth: '363px',
    },
  },
  text: {
    xsmall: {
      size: '9px',
      height: '13px',
      maxWidth: '149px',
    },
    small: {
      size: '11px',
      height: '15px',
      maxWidth: '171px',
    },
    medium: {
      size: '12px',
      height: '16px',
      maxWidth: '192px',
    },
    large: {
      size: '15px',
      height: '19px',
      maxWidth: '235px',
    },
    xlarge: {
      size: '17px',
      height: '21px',
      maxWidth: '277px',
    },
    xxlarge: {
      size: '23px',
      height: '27px',
      maxWidth: '363px',
    },
  },
};

export const defaultTheme = deepMerge(grommet, appTheme);
