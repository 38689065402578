import {
  Box,
  Button,
  ColumnConfig,
  DataTable,
  Spinner,
  Text,
  Image,
} from 'grommet';
import { JobStatusesDisplay, Statuses } from '../utils/enums';
import { campaignService } from '../../../service/campaign.service';
import { getListExternalFilesAction } from '../../../redux/actions/soil-campaign-actions';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { StyledTooltip } from '../../../components/common/StyledTooltip';
import warningIcon from '../../../assets/icons/warning.svg';

const CampaignSummaryComponent = (data: { campaignData: any }) => {
  const [jobsLoading, setJobsLoading] = useState<Record<string, boolean>>({});
  const shouldShowCsvButton = (job: { status: string; hasPhoto: boolean }) => {
    return (
      (job.hasPhoto &&
        [Statuses['in-progress'], Statuses['lab-analysis']].includes(
          job.status,
        )) ||
      job.status === Statuses.completed
    );
  };
  const dispatch = useDispatch();
  const donwloadButton = (job: {
    id: string;
    fieldName: string;
    status: string;
    hasPhoto: boolean;
  }) => {
    return (
      <StyledTooltip
        disabled={shouldShowCsvButton(job)}
        contentDisabled={!shouldShowCsvButton(job)}
        label={`${
          job.status === Statuses['in-progress'] ? 'Photos' : 'Lab results'
        } are not available yet`}
      >
        <Button
          data-cy={'job-lab-result'}
          className={'generate-files-button'}
          style={{
            width: '140px',
            textAlign: 'center',
            height: '40px',
            lineHeight: '15px',
            padding: '0',
          }}
          label={
            jobsLoading[job.id] ? (
              <Box direction='row' alignContent={'center'}>
                <Box
                  style={{ margin: '0 auto' }}
                  className={'generate-files-spinner'}
                >
                  <Spinner size={'small'} color={'#fff'} />
                </Box>
              </Box>
            ) : (
              <>{job.status === 'completed' ? 'Lab Results' : 'Photos'}</>
            )
          }
          onClick={() => {
            if (jobsLoading[job.id]) return;
            setJobsLoading((state) => {
              return { ...state, [job.id]: true };
            });
            const filename =
              job.status === Statuses.completed ? 'sampling_results' : 'photos';
            campaignService
              .getResultsCsvFileUrl(
                data.campaignData.id,
                [job.id],
                `${filename}-${data.campaignData.name}-${job.fieldName}`,
              )
              .then((result: any) => {
                campaignService.saveFileFromUrl(result.zipUrl);
                setJobsLoading((state) => {
                  return { ...state, [job.id]: false };
                });
                dispatch(getListExternalFilesAction(data.campaignData.id));
              })
              .catch(() => {
                setJobsLoading((state) => {
                  return { ...state, [job.id]: false };
                });
              });
          }}
        />
      </StyledTooltip>
    );
  };
  const columns: ColumnConfig<any>[] = [
    {
      property: 'growerName',
      header: <Text>Grower Name</Text>,
      primary: true,
      sortable: true,
      render: (rowData) => (
        <Box direction={'row'} align={'center'}>
          {(!rowData.salesforceId ||
            !rowData.journeyId ||
            !rowData.platformFieldId) && (
            <StyledTooltip
              dropOptions={'right'}
              width={'142px'}
              label={`Couldn't match ${
                (!rowData.salesforceId || !rowData.journeyId) &&
                !rowData.platformFieldId
                  ? 'producer and field'
                  : !rowData.salesforceId || !rowData.journeyId
                  ? 'producer'
                  : 'field'
              } to the Platform`}
            >
              <Image
                height={24}
                width={24}
                margin={{ right: '8px' }}
                src={warningIcon}
              />
            </StyledTooltip>
          )}
          <Text>{rowData.growerName || '-'}</Text>
        </Box>
      ),
    },
    {
      property: 'status',
      header: <Text>Status</Text>,
      sortable: true,
      render: (rowData) => (
        <Text>
          {JobStatusesDisplay[
            rowData.status as keyof typeof JobStatusesDisplay
          ] || rowData.status}
        </Text>
      ),
    },
    {
      property: 'farmName',
      header: <Text>Farm Name</Text>,
      sortable: true,
      render: (rowData) => <Text>{rowData.farmName || '-'}</Text>,
    },
    {
      property: 'fieldName',
      header: <Text>Field Name</Text>,
      sortable: true,
      render: (rowData) => <Text>{rowData.fieldName || '-'}</Text>,
    },
    {
      property: 'area',
      header: <Text>Acres</Text>,
      sortable: true,
      render: (rowData) => <Text>{rowData.area?.toFixed(3) || '-'}</Text>,
    },
    {
      property: 'sampleCount',
      header: <Text>Strata Points</Text>,
      sortable: true,
      render: (rowData) => <Text>{rowData.sampleCount || '-'}</Text>,
    },
    {
      property: 'fieldBoundaryFileName',
      header: <Text>Field boundary file name</Text>,
      sortable: true,
      render: (rowData) => <Text>{rowData.fieldBoundaryFileName || '-'}</Text>,
    },
  ];
  if (data.campaignData.jobs.some((j: any) => shouldShowCsvButton(j))) {
    columns.push({
      property: 'downloadResultsCsv',
      header: <Text></Text>,
      size: 'small',
      plain: true,
      render: donwloadButton,
    });
  }
  return (
    <Box pad={'small'}>
      <DataTable
        data-cy={'job-list-table'}
        columns={columns}
        data={data.campaignData.jobs}
        background={{
          body: ['#fff', '#F3F6F9'],
        }}
        pad={{
          header: '1rem 0.5rem 1rem 0.5rem',
          body: '1rem 0.5rem 1rem 0.5rem',
        }}
      />
    </Box>
  );
};

export default CampaignSummaryComponent;
