import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Text,
  Spinner,
  Button,
  TextArea,
  ThemeContext,
  Select,
} from 'grommet';
import * as React from 'react';
import checkFileUpload from '../../assets/upload-modal/check-file-upload.svg';
import errorUpload from '../../assets/upload-modal/error-upload.svg';
import uploadFile from '../../assets/upload-modal/upload-file.svg';
import trashCan from '../../assets/upload-modal/trash-can.svg';
import {
  getCreateCampaignAction,
  getResetCampaignCreationStateAction,
  getSendFileAction,
} from '../../redux/actions/soil-campaign-actions';
import { useState } from 'react';

const UploadCampaignModal = ({
  closeModal,
  salesforceId,
  journeyId,
  campaignId,
  uploadButtonLabel,
}: {
  closeModal: () => void;
  salesforceId?: string;
  journeyId?: string;
  campaignId?: string;
  uploadButtonLabel?: string;
}) => {
  const dispatch = useDispatch();
  const { uploadState, creationState } = useSelector((state: any) => {
    return {
      uploadState: state.soilCampaign.campaignUploadState,
      creationState: state.soilCampaign.campaignCreationState,
    };
  });
  const [file, setFile] = useState<File>();
  const [notes, setNotes] = useState<string>();
  const [cycle, setCycle] = useState<number>(1);

  return (
    <Box>
      {!file && (
        <Box>
          <input
            data-cy={'campaign-file-input'}
            onChange={(e) => {
              dispatch(getResetCampaignCreationStateAction());
              const file = e.target.files[0];
              setFile(file);
              dispatch(
                getSendFileAction(
                  'ARCGIS',
                  file,
                  salesforceId,
                  journeyId,
                  campaignId,
                ),
              );
            }}
            multiple={true}
            type='file'
            style={{
              cursor: 'pointer',
              opacity: 0.0,
              position: 'absolute',
              width: '428px',
              minHeight: !file ? '212px' : '135px',
            }}
          />
          <Box
            background={'rgba(242, 242, 242, 0.5)'}
            round={'5px'}
            pad={!file ? '16px' : '40px 70px'}
            border={{ color: '#808080', size: '2px', style: 'dashed' }}
          >
            {!file && (
              <img
                src={uploadFile}
                height={112}
                style={{ marginBottom: '16px' }}
                alt='draw on map Icon'
              />
            )}
            <Box direction={'row'} alignSelf={'center'} gap={'4px'}>
              <Text
                textAlign={'center'}
                size={'16px'}
                style={{ lineHeight: '16px', textDecorationLine: 'underline' }}
                weight={800}
                color={'#114A8F'}
              >
                Click to upload
              </Text>
              <Text
                textAlign={'center'}
                size={'16px'}
                style={{ lineHeight: '16px' }}
                weight={800}
                color={'#282828'}
              >
                or drag it here
              </Text>
            </Box>
            <Text
              textAlign={'center'}
              size={'10px'}
              style={{ lineHeight: '13px', marginTop: '8px' }}
              weight={400}
              color={'#282828'}
            >
              Currently supports only .ZIP files
            </Text>
            <Text
              textAlign={'center'}
              size={'10px'}
              style={{ lineHeight: '13px' }}
              weight={800}
              color={'#282828'}
            >
              Only 1 file at a time
            </Text>
          </Box>
        </Box>
      )}

      {file && (
        <Box overflow={'auto'} style={{ maxWidth: '100%' }}>
          <Box
            align={'center'}
            justify={'between'}
            direction={'row'}
            border={{ color: '#CCCCCC', size: '1px' }}
            style={{
              marginTop: '8px',
              borderRadius: '5px',
              padding: '16px',
              minHeight: '66px',
            }}
          >
            <Box direction={'row'}>
              {uploadState.lifeCycle === 'SUCCESS' ? (
                <img
                  width={'28px'}
                  src={checkFileUpload}
                  alt={'file uploaded check'}
                />
              ) : uploadState.lifeCycle === 'ERROR' ? (
                <img
                  width={'28px'}
                  src={errorUpload}
                  alt={'file uploaded error'}
                />
              ) : (
                <Box width={'32px'} alignSelf={'center'}>
                  <Spinner color={'#282828'} size={'1px'} />
                </Box>
              )}

              <Box
                direction={'column'}
                margin={{ left: '16px' }}
                style={{ maxWidth: '430px' }}
              >
                <Text
                  size={'16px'}
                  style={{ lineHeight: '16px', wordWrap: 'break-word' }}
                  weight={400}
                  color={'#282828'}
                >
                  {file.name}
                </Text>
                <Text
                  size={'16px'}
                  style={{ lineHeight: '16px' }}
                  weight={400}
                  color={'#808080'}
                >
                  {file.size} KB
                </Text>
              </Box>
            </Box>

            {['NONE', 'PENDING'].indexOf(uploadState.lifeCycle) === -1 && (
              <img
                data-cy={'remove-file-button'}
                onClick={() => setFile(undefined)}
                width={'24px'}
                style={{ cursor: 'pointer' }}
                src={trashCan}
                alt={'file uploaded error'}
              />
            )}
          </Box>
          {uploadState.lifeCycle === 'ERROR' && (
            <Text
              weight={400}
              size={'16px'}
              color={'#D03450'}
              margin={{ top: '8px' }}
            >
              {uploadState.data.err.message}
            </Text>
          )}
          {creationState.lifeCycle === 'TIMEOUT' && (
            <Text
              weight={400}
              size={'16px'}
              color={'#282828'}
              margin={{ top: '8px' }}
            >
              This campaign will take a while to be processed, please wait for
              the confirmation email.
            </Text>
          )}
          {creationState.lifeCycle === 'ERROR' && (
            <Text
              weight={400}
              size={'16px'}
              color={'#D03450'}
              margin={{ top: '8px' }}
            >
              The campaign could not be created, try again!
            </Text>
          )}
        </Box>
      )}

      {file &&
        uploadState.lifeCycle === 'SUCCESS' &&
        creationState.lifeCycle !== 'TIMEOUT' && (
          <>
            <Text
              style={{
                marginTop: '16px',
                fontSize: '16px',
                lineHeight: '20px',
              }}
            >
              Notes
            </Text>
            <TextArea
              data-cy={'campaign-notes'}
              onChange={(event) => setNotes(event.target.value)}
              name='notes'
              size={'medium'}
              resize={'vertical'}
              placeholder={'Start typing...'}
              style={{
                marginTop: '4px',
                borderColor: '#CCCCCC',
                borderWidth: '1px',
                padding: '16px',
                minHeight: '66px',
              }}
            />
            <Text
              style={{
                marginTop: '16px',
                fontSize: '16px',
                lineHeight: '20px',
              }}
            >
              Sampling Cycle
            </Text>
            {/*This ThemeContext is here because there is a theme for the select component (RenameCampaignModal)*/}
            <ThemeContext.Extend
              value={{
                button: {
                  padding: {
                    vertical: '4px',
                    horizontal: '8px',
                  },
                  extend: '',
                },
                select: {
                  control: {
                    extend: 'border: 1px solid #CCCCCC; background: #fff',
                    open: {
                      border: 'solid 1px #CCCCCC',
                    },
                  },
                },
              }}
            >
              <Select
                margin={{ top: '4px' }}
                data-cy={'dropdown-cycle'}
                defaultValue={cycle}
                focusIndicator={false}
                options={['1st cycle', '2nd cycle', '3rd cycle'].map(
                  (key, index) => {
                    return { cycle: index + 1, value: key };
                  },
                )}
                labelKey={'value'}
                valueKey={{ key: 'cycle', reduce: true }}
                onChange={(event) => setCycle(event.target.value)}
                name='cycle'
              />
            </ThemeContext.Extend>
          </>
        )}

      {file && uploadState.lifeCycle === 'SUCCESS' && (
        <Button
          data-cy={'confirm-campaign-creation-button'}
          disabled={creationState.lifeCycle === 'TIMEOUT'}
          primary
          style={{ justifyContent: 'center' }}
          label={
            creationState.lifeCycle === 'PENDING' ? (
              <Box direction='row' alignContent={'center'} justify={'center'}>
                <Spinner size={'small'} color={'#000'} />
              </Box>
            ) : (
              <Text size={'16px'} weight={800}>
                {uploadButtonLabel ? uploadButtonLabel : 'Create Campaign'}
              </Text>
            )
          }
          margin={{ top: '16px' }}
          onClick={() => {
            dispatch(getCreateCampaignAction({ ...uploadState, notes, cycle }));
          }}
        />
      )}

      <Text
        data-cy={'cancel-campaign-creation-button'}
        onClick={() => {
          if (creationState.lifeCycle === 'PENDING') return;
          closeModal();
        }}
        style={{
          cursor: creationState.lifeCycle === 'PENDING' ? 'default' : 'pointer',
        }}
        textAlign={'center'}
        size={'16px'}
        weight={400}
        margin={{ top: '16px' }}
        color={creationState.lifeCycle === 'PENDING' ? '#CCC' : '#282828'}
      >
        {creationState.lifeCycle === 'TIMEOUT' ? 'Close' : 'Cancel'}
      </Text>
    </Box>
  );
};

export default UploadCampaignModal;
