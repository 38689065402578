export const JobStatusesDisplay = {
  'in-review': '---',
  archived: 'Archived',
  NOT_SENT: '---',
  'no-points': 'No Points',
  'files-generated': 'Files Generated',
  new: 'Received by Vendor',
  planned: 'Planned',
  'in-progress': 'In-progress',
  'lab-analysis': 'Lab-analysis',
  completed: 'Completed',
  cancelled: 'Canceled',
};

export const CampaignStatusesDisplay = {
  UNKNOWN: 'Unknown',
  'in-review': 'In Review',
  'files-generated': 'Files Generated',
  new: 'Received by Vendor',
  planned: 'Planned',
  'in-progress': 'In-progress',
  'lab-analysis': 'Lab-analysis',
  completed: 'Completed',
  cancelled: 'Cancelled',
  errored: 'Errored',
  archived: 'Archived',
  'awaiting-sync': 'Awaiting Sync',
  'sync-error': 'Sync Error',
};

//todo: change unknown and cancelled
export const CampaignStatusesTooltipDisplay = {
  // UNKNOWN: 'Unknown status',
  'in-review': 'Campaign is currently being reviewed by GST',
  'files-generated': 'Campaign is reviewed and ready to be sent to vendor',
  new: 'Campaign was received by the vendor',
  planned: 'Vendor is planning to sample the location',
  'in-progress': 'Vendor is currently sampling the location',
  'lab-analysis': 'Samples have been collected and are under lab analysis',
  completed: 'Samples collected and lab results in',
  cancelled: 'Campaign was duplicated and cancelled by Deveron',
  errored: 'Error while creating campaign',
  archived: 'Archived',
  'awaiting-sync': 'Campaign is currently being sent to vendor',
  'sync-error': 'Error while sending campaign to vendor',
};

export const Statuses = {
  UNKNOWN: 'UNKNOWN',
  archived: 'archived',
  errored: 'errored',
  cancelled: 'cancelled',
  'in-review': 'in-review',
  'files-generated': 'files-generated',
  new: 'new',
  planned: 'planned',
  'in-progress': 'in-progress',
  'lab-analysis': 'lab-analysis',
  completed: 'completed',
  'awaiting-sync': 'awaiting-sync',
  'sync-error': 'sync-error',
};
