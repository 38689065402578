import { Anchor, Box, Text } from 'grommet';
import React from 'react';
import { toast } from 'react-toastify';

export const showToast = (
  title: string,
  message: string,
  type: string,
  toastId?: string,
  undoText?: string,
  undoCallback?: () => void,
  persist?: boolean,
) => {
  const SuccessContent = (
    <React.Fragment>
      <Box data-cy={toastId} direction={'column'}>
        <Text size='medium' weight='bold' color='#191408'>
          {title}
        </Text>
        <Text size='medium' color='#515564'>
          {message}
        </Text>
        {undoText && (
          <Anchor
            color='#1370BC'
            style={{ marginTop: '1rem' }}
            onClick={undoCallback}
          >
            {undoText}
          </Anchor>
        )}
      </Box>
    </React.Fragment>
  );

  const messageType: any = {
    success: '#07bc0c',
    warning: '#f1c40f',
    error: '#ff4a6b',
    info: '#3498db',
  };
  toast(SuccessContent, {
    toastId: toastId,
    style: {
      borderLeft: `5px solid ${messageType[type]}`,
    },
    position: 'top-right',
    autoClose: persist || undoText ? false : 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    closeButton: (props) => (
      <Anchor size='small' color='#1370BC' onClick={props.closeToast}>
        close
      </Anchor>
    ),
  });
};
