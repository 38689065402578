import { useAuth0 } from '@auth0/auth0-react';
import { Box, Spinner } from 'grommet';

export interface EnsureAuthenticatedProps {
  children: React.ReactNode;
}

export function EnsureAuthenticated({ children }: EnsureAuthenticatedProps) {
  const { isLoading, isAuthenticated, error, loginWithRedirect } = useAuth0();

  const loader = (
    <Box direction={'row'} align={'center'} pad={'0.5rem 1rem'}>
      <div style={{ margin: '1rem auto' }}>
        <Spinner color={'#FFE137'} size={'large'} />
      </div>
    </Box>
  );

  if (isLoading) {
    return loader;
  }
  if (error) {
    return <div>Oops... {error.message}</div>;
  }
  if (!isAuthenticated) {
    loginWithRedirect();
    return loader;
  } else {
    return <div>{children}</div>;
  }
}

export default EnsureAuthenticated;
