import { Box, RadioButtonGroup, Spinner, Text, TextArea } from 'grommet';
import { useEffect, useState } from 'react';
import { FormNext } from 'grommet-icons';
import { campaignService } from '../../../service/campaign.service';
import { showToast } from '../../../providers/ToastProvider';
import 'mapbox-gl/dist/mapbox-gl.css';
import { StyledTooltip } from '../../../components/common/StyledTooltip';

const PointDataComponent = (props: {
  point: any;
  history: any;
  updateHistory: (point: any) => void;
  disabled?: boolean;
}) => {
  const [status, setStatus] = useState(props.point.status);
  const [comment, setComment] = useState('');
  const [commentLoading, setCommentLoading] = useState(false);
  const [showStatusSpinner, setShowStatusSpinner] = useState(false);

  const postCommentHandler = () => {
    if (!comment) return;
    setCommentLoading(true);
    campaignService
      .addPointComment(props.point.campaignId, props.point.pointId, comment)
      .then(() => {
        setComment('');
        showToast('Success', 'Comment was added successfully', 'success');
        props.updateHistory(props.point);
      })
      .catch(() => {
        showToast(
          'Error',
          'Sorry, but we could not add the comment. Try again later!',
          'error',
        );
      })
      .finally(() => {
        setCommentLoading(false);
      });
  };

  useEffect(() => {
    if (status && status !== props.point.status) {
      setShowStatusSpinner(true);
      const oldStatus = props.point.status;
      props.point.status = status;
      const setElementStatus = (status: string) => {
        const marker = props.point.marker;
        marker.getElement().classList.remove('marker-approved');
        marker.getElement().classList.remove('marker-in-review');
        marker.getElement().classList.remove('marker-rejected');
        switch (status) {
          case 'APPROVED':
            marker.getElement().classList.add('marker-approved');
            break;
          case 'REJECTED':
            marker.getElement().classList.add('marker-rejected');
            break;
          default:
            marker.getElement().classList.add('marker-in-review');
        }
      };
      setElementStatus(props.point.status);
      campaignService
        .updateSamplingPoint(props.point.campaignId, props.point.pointId, {
          status: props.point.status,
        })
        .then(() => {
          showToast(
            'Success',
            'Sample status was updated successfully',
            'success',
          );
          props.updateHistory(props.point);
        })
        .catch(() => {
          showToast(
            'Error',
            'Sorry, but we could not update this point. Try again later!',
            'error',
          );
          props.point.status = oldStatus;
          setElementStatus(oldStatus);
          setStatus(oldStatus);
        })
        .finally(() => {
          setShowStatusSpinner(false);
        });
    }
  }, [status, props]);

  return (
    <Box pad={'medium'} className={'fade-in'}>
      <div
        style={{
          width: '100%',
          borderTop: 'solid 1px #dedede',
          paddingBottom: '1rem',
        }}
      />

      <Text
        style={{
          fontWeight: 800,
          fontSize: '20px',
          lineHeight: '32px',
          paddingBottom: '5px',
        }}
      >
        Write a comment
      </Text>

      <Box style={{ position: 'relative', paddingBottom: '10px' }}>
        <TextArea
          style={{ paddingRight: '2rem', height: '80px', fontWeight: 'normal' }}
          placeholder={'Type a comment'}
          resize={false}
          value={comment}
          onChange={(event) => setComment(event.target.value)}
        />
        {!commentLoading ? (
          <FormNext
            onClick={postCommentHandler}
            style={{
              cursor: comment ? 'pointer' : 'default',
              opacity: comment ? '1' : '0.4',
              background: '#FFE137',
              borderRadius: '50%',
              position: 'absolute',
              bottom: '15px',
              right: '5px',
            }}
          />
        ) : (
          <Box
            style={{
              position: 'absolute',
              bottom: '15px',
              right: '5px',
            }}
          >
            <Spinner size={'xsmall'} color={'#000000'} />
          </Box>
        )}
      </Box>

      <StyledTooltip
        label={'You can’t edit points after generating files'}
        contentDisabled={status === 'SKIPPED' || props.disabled}
        disabled={!(status === 'SKIPPED' || props.disabled)}
      >
        <Box
          direction={'row'}
          gap={'medium'}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <Text
            style={{
              fontWeight: 800,
              fontSize: '20px',
              lineHeight: '32px',
            }}
          >
            Change status:
          </Text>
          {showStatusSpinner && <Spinner color={'#FFE137'} size={'small'} />}
        </Box>

        <Box
          pad={'small'}
          style={{
            fontSize: '14px',
          }}
        >
          <RadioButtonGroup
            name='pointsStatus'
            options={[
              { label: 'Approve', value: 'APPROVED' },
              { label: 'In Review', value: 'IN_REVIEW' },
              { label: 'Reject', value: 'REJECTED' },
            ]}
            value={status}
            onChange={(event) => setStatus(event.target.value)}
          />
        </Box>
      </StyledTooltip>
    </Box>
  );
};

export default PointDataComponent;
