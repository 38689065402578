import mapboxgl from 'mapbox-gl';
import axios from 'axios';

export const SAMPLING_POINT_STATUS = [
  { label: 'Approved', value: 'APPROVED', markerClass: 'marker-approved' },
  { label: 'In Review', value: 'IN_REVIEW', markerClass: 'marker-in-review' },
  { label: 'Rejected', value: 'REJECTED', markerClass: 'marker-rejected' },
];

const addStrataLayerToMap = async (
  strataUrl: string,
  map: mapboxgl.Map,
  onLoadProgress: (percent: number) => void,
) => {
  let jsonData = await axios
    .get(strataUrl, {
      onDownloadProgress: (progressEvent) => {
        const current = progressEvent.currentTarget.response.length;
        const total = parseFloat(
          progressEvent.srcElement.getResponseHeader(
            'x-amz-meta-original-size',
          ),
        );
        let percentCompleted = Math.floor((current / total) * 100);
        onLoadProgress(total ? percentCompleted : -1);
      },
    })
    .then((res) => {
      return res.data;
    });
  map.addSource('field-strata', {
    type: 'geojson',
    data: jsonData,
  });
  map.addLayer({
    id: 'field-strata',
    type: 'fill',
    source: 'field-strata',
    minzoom: 11,
    layout: {
      visibility: 'none',
    },
    paint: {
      'fill-color': ['get', 'color'],
      'fill-outline-color': '#fff',
      'fill-opacity': 0.3,
    },
  });
};

const addBoundariesLayerToMap = (boundaryUrl: string, map: mapboxgl.Map) => {
  map.addSource('field-boundaries', {
    type: 'geojson',
    data: boundaryUrl,
  });
  map.addLayer({
    id: 'field-boundaries',
    type: 'line',
    source: 'field-boundaries',
    minzoom: 11,
    layout: {
      visibility: 'none',
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-width': 3,
      'line-color': '#00A1FF',
    },
  });
};

const addSamplingPointsSourceToMap = (map: any, points: any) => {
  // Add a new source from our GeoJSON data and
  // set the 'cluster' option to true. GL-JS will
  // add the point_count property to your source data.
  map.addSource('samplingPointsSource', {
    type: 'geojson',
    data: points,
    cluster: true,
    clusterMaxZoom: 10, // Max zoom to cluster points on
    clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
  });

  map.addLayer({
    id: 'clusters',
    type: 'circle',
    source: 'samplingPointsSource',
    filter: ['has', 'point_count'],
    paint: {
      // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
      // with three steps to implement three types of circles:
      //   * Blue, 20px circles when point count is less than 100
      //   * Yellow, 30px circles when point count is between 100 and 750
      //   * Pink, 40px circles when point count is greater than or equal to 750
      'circle-stroke-width': 2,
      'circle-stroke-color': '#fff',
      'circle-color': [
        'step',
        ['get', 'point_count'],
        '#51bbd6',
        100,
        '#f1f075',
        750,
        '#f28cb1',
      ],
      'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
    },
  });

  map.addLayer({
    id: 'cluster-count',
    type: 'symbol',
    source: 'samplingPointsSource',
    filter: ['has', 'point_count'],
    layout: {
      'text-field': '{point_count_abbreviated}',
      'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
      'text-size': 12,
    },
  });

  // inspect a cluster on click
  map.on('click', 'clusters', (e: any) => {
    const features = map.queryRenderedFeatures(e.point, {
      layers: ['clusters'],
    });
    const clusterId = features[0].properties.cluster_id;
    map
      .getSource('samplingPointsSource')
      .getClusterExpansionZoom(clusterId, (err: any, zoom: any) => {
        if (err) return;

        map.easeTo({
          center: features[0].geometry.coordinates,
          zoom: zoom,
        });
      });
  });
  map.on('mouseenter', 'clusters', () => {
    map.getCanvas().style.cursor = 'pointer';
  });
  map.on('mouseleave', 'clusters', () => {
    map.getCanvas().style.cursor = '';
  });
};

export const MapService = {
  addStrataLayerToMap,
  addSamplingPointsSourceToMap,
  addBoundariesLayerToMap,
};
