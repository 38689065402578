import { Box, DropProps, Text, Tip } from 'grommet';
import React from 'react';
import { CSSProperties } from 'styled-components';

interface TooltipProps {
  label: string;
  disabled?: boolean;
  contentDisabled?: boolean;
  dropOptions?: 'top' | 'bottom' | 'left' | 'right';
  width?: string;
  children: React.ReactNode;
}

export const TooTip = ({
  label,
  drop,
}: {
  label: string;
  drop: 'top' | 'bottom' | 'left' | 'right';
}): JSX.Element => {
  return (
    <Box
      direction={['left', 'right'].includes(drop) ? 'row' : 'column'}
      align={'center'}
      justify={'center'}
      margin={'0 auto'}
    >
      {drop === 'bottom' && (
        <Box
          margin={'0 auto'}
          style={{
            width: 0,
            height: 0,
            borderLeft: '7.5px solid transparent',
            borderRight: '7.5px solid transparent',
            borderBottom: '10px solid #282828',
          }}
        />
      )}
      {drop === 'right' && (
        <Box
          margin={'auto 0'}
          style={{
            width: 0,
            height: 0,
            borderStyle: 'solid',
            borderWidth: '7.5px 10px 7.5px 0',
            borderColor: 'transparent #282828 transparent transparent',
          }}
        />
      )}
      <Box
        round={'0.25rem'}
        pad={'0.25rem 0.5rem'}
        height={'fit-content'}
        justify={'center'}
        background={{ color: '#282828' }}
      >
        <Text color={'#FFFFFF'} size={'0.75rem'} textAlign={'center'}>
          {label}
        </Text>
      </Box>
      {drop === 'left' && (
        <Box
          margin={'auto 0'}
          style={{
            width: 0,
            height: 0,
            borderStyle: 'solid',
            borderWidth: '7.5px 0 7.5px 10px',
            borderColor: 'transparent transparent transparent #282828',
          }}
        />
      )}
      {drop === 'top' && (
        <Box
          margin={'0 auto'}
          style={{
            width: 0,
            height: 0,
            borderLeft: '7.5px solid transparent',
            borderRight: '7.5px solid transparent',
            borderTop: '10px solid #282828',
          }}
        />
      )}
    </Box>
  );
};

export const StyledTooltip = ({
  label,
  disabled,
  contentDisabled,
  dropOptions,
  width,
  children,
}: TooltipProps): JSX.Element => {
  const disabledLook: CSSProperties = { opacity: 0.4, pointerEvents: 'none' };
  const dropOptionsAlign: Record<string, DropProps> = {
    bottom: { align: { top: 'bottom' } },
    top: { align: { bottom: 'top' } },
    left: { align: { right: 'left' } },
    right: { align: { left: 'right' } },
  };
  const drop: DropProps = dropOptionsAlign[dropOptions || 'top'];

  if (disabled) {
    return <>{children}</>;
  }
  return (
    <Tip
      plain
      key={label.trim()}
      dropProps={drop}
      content={
        <Box width={width}>
          <TooTip drop={dropOptions} label={label} />
        </Box>
      }
    >
      <Box>
        <Box style={contentDisabled ? disabledLook : null}>{children}</Box>
      </Box>
    </Tip>
  );
};
