import { Box, Button, Grommet, Layer, Text } from 'grommet';
import * as ReactDOM from 'react-dom';

import store from '../../redux/store/index';
import { Provider as ReduxProvider } from 'react-redux';
import { Close } from 'grommet-icons';
import { defaultTheme } from '../../theme';

const AdminPortalModal = ({
  title,
  content,
  onOkayCallback,
  onCancelCallback,
  showCloseButton,
  okayButtonLabel,
  cancelButtonLabel,
  dataCy,
}: any) => {
  return (
    <Grommet theme={defaultTheme}>
      <Layer
        onEsc={() => onCancelCallback()}
        onClickOutside={() => onCancelCallback()}
      >
        <Box
          data-cy={dataCy}
          direction='column'
          style={{
            padding: '2.5rem',
            minWidth: '500px',
            boxShadow:
              '0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)',
          }}
        >
          {showCloseButton && (
            <Close
              data-cy={`${dataCy}-close-modal`}
              onClick={onCancelCallback}
              size={'small'}
              style={{
                position: 'absolute',
                top: '17px',
                right: '17px',
                cursor: 'pointer',
              }}
            />
          )}
          {title && (
            <Box>
              <Text
                style={{
                  textAlign: 'left',
                  fontFamily: 'Noto Sans',
                  fontStyle: 'normal',
                  fontWeight: '700',
                  fontSize: '24px',
                  lineHeight: '40px',
                }}
              >
                {title}
              </Text>
            </Box>
          )}
          <Box style={{ paddingTop: '16px' }}>
            <Text
              size={'medium'}
              style={{
                textAlign: 'left',
                fontFamily: 'Noto Sans',
                fontStyle: 'normal',
                fontSize: '16px',
                lineHeight: '40px',
              }}
            >
              {content}
            </Text>
          </Box>
          {onOkayCallback && (
            <Box
              justify={'end'}
              direction={'row'}
              gap={'medium'}
              style={{ paddingTop: '36px' }}
            >
              <Button
                data-cy={`${dataCy}-cancel-button`}
                onClick={onCancelCallback}
                style={{ border: '1px solid #ffe137' }}
              >
                {cancelButtonLabel}
              </Button>
              <Button
                data-cy={`${dataCy}-okay-button`}
                onClick={onOkayCallback}
                primary
              >
                {okayButtonLabel}
              </Button>
            </Box>
          )}
        </Box>
      </Layer>
    </Grommet>
  );
};

// TODO: Right now the onOkayCallback is not used, the vision is in the future the modal will have default buttons okay and cancel, and it will be common across the app.  When the user clicks on those buttons the callbacks will be called.
const showModal = (
  title: string | JSX.Element | null,
  content: string | JSX.Element,
  onOkayCallback: () => any = null,
  onCancelCallback: () => any = null,
  showCloseButton: boolean = true,
  okayButtonLabel: string = 'Okay',
  cancelButtonLabel: string = 'Cancel',
  dataCy?: string,
) => {
  const containerDiv = document.createElement('div');
  document.body.appendChild(containerDiv);
  // Return removeModal so it can be removed by caller programmatically, put in setTimeout to take it out of the react rendering cycle so it doesn't complain
  const removeModal = (reason: string) => {
    setTimeout(() => {
      const unmountResult = ReactDOM.unmountComponentAtNode(containerDiv);
      if (unmountResult && containerDiv.parentNode) {
        containerDiv.parentNode.removeChild(containerDiv);
      }
      if (onCancelCallback && reason === 'cancel') {
        onCancelCallback();
      }
    });
  };

  const onConfirmModal = () => {
    removeModal('success');
    onOkayCallback();
  };

  ReactDOM.render(
    <ReduxProvider store={store}>
      <AdminPortalModal
        title={title}
        content={content}
        showCloseButton={showCloseButton}
        onOkayCallback={onOkayCallback ? onConfirmModal : null}
        onCancelCallback={() => removeModal('cancel')}
        okayButtonLabel={okayButtonLabel}
        cancelButtonLabel={cancelButtonLabel}
        dataCy={dataCy ?? 'modal'}
      />
    </ReduxProvider>,
    containerDiv,
  );
  return removeModal;
};

export default showModal;
