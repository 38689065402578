import { Text, Box, Image, Button } from 'grommet';
const ImageCard = ({
  customGraphics,
  imageSrc,
  title,
  description,
  buttonText,
  buttonCallback,
  dataCy,
}: any) => {
  return (
    <Box
      data-cy={dataCy}
      pad='large'
      align='center'
      direction='column'
      gap='15px'
      elevation='medium'
      style={{
        width: '100%',
        border: '1px solid #CCCCCC',
        boxShadow:
          '0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)',
        borderRadius: '12px',
      }}
    >
      {imageSrc ? <Image width={100} src={imageSrc} /> : null}
      {customGraphics ? customGraphics : null}
      <Text weight='bold' size='large'>
        {title}
      </Text>
      <Text>{description}</Text>
      {buttonText ? (
        <Button
          data-cy={`${dataCy}-button`}
          onClick={buttonCallback}
          primary
          style={{ width: '100%', textAlign: 'center' }}
        >
          {buttonText}
        </Button>
      ) : null}
    </Box>
  );
};
export default ImageCard;
