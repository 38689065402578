import { Down, FormPrevious } from 'grommet-icons';
import React, { useState } from 'react';
import { Avatar, Box, DropButton, Text, Anchor } from 'grommet';
import { useAuth0 } from '@auth0/auth0-react';

export interface AdminHeaderProps {
  backNavigate: boolean;
  backButtonTitle?: string;
  backButtonCallback?: () => void;
}

function makeInitials(name: string): string {
  const res = name.split(' ');
  const res1: string = res[0];
  const res2: string = res[1];

  const firstInitials = res1?.split('#').map(function (str) {
    return str ? str[0].toUpperCase() : '';
  });
  const lastInitials = res2?.split('#').map(function (str) {
    return str ? str[0].toUpperCase() : '';
  });
  return firstInitials.concat(lastInitials).join('');
}

export function AdminHeader({
  backButtonTitle,
  backNavigate,
  backButtonCallback,
}: AdminHeaderProps) {
  const [profileMenuOpened, setProfileMenuOpened] = useState(false);
  const { user, logout } = useAuth0();
  const prodEnvironment = process.env.REACT_APP_ENV === 'prod';
  const userFullName = user.name || user.email;
  const userInitials = makeInitials(userFullName);

  const menuItems = [
    {
      key: '1',
      label: (
        <Box alignSelf='center'>
          <div>{user?.email}</div>
        </Box>
      ),
      icon: (
        <Box>
          <Avatar
            style={{
              margin: 'auto',
              width: '24px',
              height: '24px',
              marginRight: '5px',
            }}
            background='#e9e9eb'
            size={'small'}
            flex={false}
          >
            <span style={{ fontWeight: 'bold', fontSize: '10px' }}>
              {userInitials}
            </span>
          </Avatar>
        </Box>
      ),
    },
    {
      key: '2',
      label: (
        <Box style={{ width: '100%' }} pad={{ left: '29px' }}>
          Logout
        </Box>
      ),
      onClick: () => {
        logout({ returnTo: window.location.origin });
      },
    },
  ];

  return (
    <>
      {!prodEnvironment && (
        <Box
          style={{
            position: 'fixed',
            top: '0px',
            left: '0px',
            zIndex: 999,
          }}
          align={'center'}
          justify={'center'}
          width={'100%'}
          height={'40px'}
          border={{ color: '#D03450', size: '1px' }}
          background={'rgb(248,216,220)'}
        >
          <Text size={'16px'}>
            You are on a <strong>testing environment</strong>. Inserted data{' '}
            <strong>will not</strong> be on Salesforce.
          </Text>
        </Box>
      )}
      <Box
        flex={false}
        as={'header'}
        gap='medium'
        height='5em'
        width='100%'
        style={{
          marginTop: !prodEnvironment ? '40px' : '0px',
          background: '#1A1A1A',
          boxShadow: 'rgb(0 0 0 / 25%) 0px 2px 8px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
        direction={'row'}
      >
        <Box>
          {backNavigate ? (
            <Box
              direction='row'
              align='center'
              margin='0 0 0 1rem'
              style={{ height: '100%' }}
            >
              <FormPrevious color='#fff' size='medium' />
              <Anchor
                data-cy={'back-button-header'}
                onClick={backButtonCallback}
                data-testid='anchor'
                size='small'
                color='#fff'
              >
                {backButtonTitle}
              </Anchor>
            </Box>
          ) : (
            <Box data-testid='no-button'>{null}</Box>
          )}
        </Box>
        <Box pad={'medium'} direction={'row'}>
          <Box>
            <Text
              style={{
                fontWeight: 'bold',
                color: '#fff',
                marginTop: '7px',
                marginRight: '10px',
              }}
            >{`Hi ${userFullName}`}</Text>
          </Box>
          <Avatar
            style={{ margin: 'auto', width: '32px', height: '32px' }}
            background='#e9e9eb'
            size={'small'}
            flex={false}
          >
            <span style={{ fontWeight: 'bold', cursor: 'default' }}>
              {userInitials}
            </span>
          </Avatar>
          <DropButton
            onClick={() => setProfileMenuOpened(!profileMenuOpened)}
            style={{
              border: 0,
              fontSize: '12px',
              background: 'rgb(26, 26, 26)',
              height: '28px',
              minHeight: '28px',
            }}
            dropAlign={{ right: 'right', top: 'bottom' }}
            label={' '}
            reverse={true}
            open={profileMenuOpened}
            onClose={() => setProfileMenuOpened(false)}
            onOpen={() => setProfileMenuOpened(true)}
            icon={<Down color={'#fff'} size={'small'} />}
            dropContent={
              <Box pad='small' background='#000' style={{ display: 'flex' }}>
                {menuItems.map((menuItem, i, arr) => (
                  <div key={menuItem.key}>
                    <div
                      style={{
                        cursor: 'pointer',
                        height: '32px',
                        alignItems: 'center',
                        display: 'flex',
                      }}
                      onClick={menuItem.onClick}
                    >
                      {menuItem.icon} {menuItem.label}
                    </div>
                    {arr.length - 1 !== i && (
                      <div style={{ width: '100%' }}>
                        <hr
                          style={{
                            borderColor: 'rgb(233, 233, 235)',
                            borderWidth: '0px 0px 1px',
                            borderStyle: 'solid',
                          }}
                        />
                      </div>
                    )}
                  </div>
                ))}
              </Box>
            }
          />
        </Box>
      </Box>
    </>
  );
}

export default AdminHeader;
