import {
  Box,
  Button,
  ColumnConfig,
  DataTable,
  Heading,
  Text,
  Spinner,
  Anchor,
  Image,
} from 'grommet';
import { useEffect, useState } from 'react';
import campaignImg from './assets/campaign.svg';
import * as React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { showToast } from '../../providers/ToastProvider';
import {
  cancelUploadFileStateAction,
  getLabsAction,
  getListCampaignsAction,
  getProducerAction,
  getResetCampaignCreationStateAction,
  getVendorsAction,
} from '../../redux/actions/soil-campaign-actions';
import showModal from '../../components/modal/modal';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import ActionDropdown from './components/ActionDropdown.component';
import {
  CampaignStatusesDisplay,
  Statuses,
} from '../campaign-upload-details/utils/enums';
import { useAuth0 } from '@auth0/auth0-react';
import UploadCampaignModal from '../../components/campaign-upload/UploadCampaignModal';
import CampaignListHeader from './components/CampaignListHeader';
import { StyledTooltip } from '../../components/common/StyledTooltip';
import warningIcon from '../../assets/icons/warning.svg';

const CampaignListPage = () => {
  const [cancelModal, setCancelModal] = useState(null);
  const [campaignsFiltered, setCampaignsFiltered] = useState(null);
  const [searchParams] = useSearchParams();
  const salesforceId = searchParams.get('salesforceId');
  const journeyId = searchParams.get('journeyId');
  const { user } = useAuth0();
  const dispatch = useDispatch();
  const {
    uploadState,
    producer,
    journey,
    creationState,
    campaignFilter,
    vendors,
  } = useSelector((state: any) => {
    return {
      uploadState: state.soilCampaign.campaignUploadState,
      producer: state.soilCampaign.producer,
      journey: state.soilCampaign.journey,
      creationState: state.soilCampaign.campaignCreationState,
      campaignFilter: state.soilCampaign.campaignFilter,
      vendors: state.soilCampaign.vendors,
    };
  });
  const navigate = useNavigate();

  //todo find a better way to check role
  const shouldShowError =
    user[process.env.REACT_APP_AUTH0_AUDIENCE + '/roles'].indexOf(
      'Sampling Campaign Creator VIP',
    ) === -1;
  const { campaignListState, campaigns } = useSelector((state: any) => {
    return {
      campaignListState: state.soilCampaign.campaignListState,
      campaigns: state.soilCampaign.campaigns,
    };
  });
  const shouldShowArchived = useSelector(({ soilCampaign }: any) => {
    return soilCampaign.shouldShowArchived;
  });
  const openToast = (message: string) => {
    showToast('Error', message, 'error');
  };

  const isErrored = (status: string) => {
    return status === Statuses['errored'];
  };

  const getErrorColor = (status: string) => {
    return shouldShowError && isErrored(status) ? '#D03450' : undefined;
  };

  useEffect(() => {
    if (salesforceId) {
      dispatch(getProducerAction(salesforceId));
    }
    dispatch(getVendorsAction());
    dispatch(getLabsAction());
    dispatch(getListCampaignsAction(salesforceId, journeyId));
  }, [dispatch, salesforceId, journeyId]);

  useEffect(() => {
    if (creationState.lifeCycle === 'SUCCESS') {
      cancelModal.callback('SUCCESS');
      if (shouldShowError && creationState.campaignId)
        navigate(`/campaigns/${creationState.campaignId}`);
      else if (creationState.message === 'CAMPAIGN_UPLOAD_TASK_CREATED')
        showToast(
          'Success',
          'Campaign upload is beeing processed and should soon be available.',
          'success',
          'campaign-upload-toast',
        );
      dispatch(getListCampaignsAction(salesforceId, journeyId));
      dispatch(getResetCampaignCreationStateAction());
    } else if (creationState.lifeCycle === 'ERROR') {
      if (
        creationState.data.response.status === 400 &&
        creationState.campaignId
      ) {
        const errorsSet = new Set(
          creationState.data.response.data.errors.map(
            (e: any) => e.split('_')[1],
          ),
        );
        const errors = [
          errorsSet.has('BOUNDARY') ? 'Boundary' : undefined,
          errorsSet.has('FIELD') ? 'Field' : undefined,
          errorsSet.has('STRATA') ? 'Strata' : undefined,
        ]
          .filter(Boolean)
          .join(', ');
        const errorMessage = `Error Generating Campaign: ${errors} Files missing`;
        openToast(errorMessage);
        cancelModal.callback('');
        navigate(`/campaigns/${creationState.campaignId}`);
        dispatch(getListCampaignsAction(salesforceId, journeyId));
        dispatch(getResetCampaignCreationStateAction());
      } else {
        openToast('Sorry, something is wrong and we can not import this file');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    creationState?.data?.statusCode,
    creationState?.lifeCycle,
    cancelModal,
    dispatch,
    navigate,
    salesforceId,
    journeyId,
  ]);

  useEffect(() => {
    if (uploadState.lifeCycle === 'CANCEL') {
      cancelModal.callback('');
      dispatch(getResetCampaignCreationStateAction());
    }
  }, [uploadState?.lifeCycle, cancelModal, dispatch]);

  useEffect(() => {
    if (campaignListState.lifeCycle === 'ERROR') {
      if (
        campaignListState.status === 401 ||
        campaignListState.status === 403
      ) {
        showToast(
          'Error',
          "You don't have permission to access this data. Please reach out to Product.",
          'error',
          'data-permission-toast',
        );
      } else {
        openToast('Sorry, but we could not list campaigns. Try again later!');
      }
    }
  }, [campaignListState?.lifeCycle, campaignListState.status]);

  useEffect(() => {
    setCampaignsFiltered(() => {
      return campaigns?.filter((campaign: any) => {
        let showCampaign =
          campaign.status === Statuses['archived']
            ? shouldShowArchived === true
            : true;
        if (campaignFilter.status) {
          showCampaign = campaign.status === campaignFilter.status;
        }
        if (showCampaign && campaignFilter.search) {
          showCampaign =
            campaign.name.match(
              new RegExp(
                campaignFilter.search.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1'),
                'gi',
              ),
            )?.length > 0;
        }
        return showCampaign;
      });
    });
    dispatch({ type: 'CAMPAIGN_FILTER_UPDATE_SUCCESS' });
  }, [campaigns, shouldShowArchived, campaignFilter, dispatch]);

  const columns: ColumnConfig<any>[] = [
    {
      header: <Text style={{ fontWeight: 'bold' }}>Campaign Name</Text>,
      property: 'name',
      render: (data) => (
        <Box direction={'row'} align={'center'}>
          {!isErrored(data.status) && (data.growerError || data.fieldError) && (
            <StyledTooltip
              dropOptions={'right'}
              width={'142px'}
              label={`Some of the campaign's jobs errored, couldn't match ${
                data.growerError && data.fieldError
                  ? 'producer and field'
                  : !data.growerError
                  ? 'producer'
                  : 'field'
              } to the platform`}
            >
              <Image
                height={24}
                width={24}
                margin={{ right: '8px' }}
                src={warningIcon}
              />
            </StyledTooltip>
          )}
          <Text
            color={getErrorColor(data.status)}
            style={{ wordWrap: 'break-word', width: '25vw' }}
          >
            {data.name}
          </Text>
        </Box>
      ),
    },
    {
      header: <Text style={{ fontWeight: 'bold' }}>Original Vendor</Text>,
      property: 'vendor',
      render: (data) => (
        <Text color={getErrorColor(data.status)}>
          {vendors[data.vendorId] ?? '---'}
        </Text>
      ),
    },
    {
      header: <Text style={{ fontWeight: 'bold' }}>Historical Status</Text>,
      property: 'status',
      render: (rowData) => (
        <Text color={getErrorColor(rowData.status)}>
          {!shouldShowError && rowData.status === Statuses['errored']
            ? 'Uploaded'
            : CampaignStatusesDisplay[
                rowData.status as keyof typeof CampaignStatusesDisplay
              ] || rowData.status}
        </Text>
      ),
    },
    {
      header: <Text style={{ fontWeight: 'bold' }}>Agoro Assessed Status</Text>,
      property: 'assessedStatus',
      render: (data) => (
        <Text color={getErrorColor(data.status)}>
          {data.assessedStatus ?? '---'}
        </Text>
      ),
    },
    {
      header: (
        <Text style={{ fontWeight: 'bold' }}>Vendor Assigned For Cleanup</Text>
      ),
      property: 'vendorForCleanup',
      render: (data) => (
        <Text color={getErrorColor(data.status)}>
          {vendors[data.vendorForCleanupId] ?? '---'}
        </Text>
      ),
    },
    {
      header: <Text style={{ fontWeight: 'bold' }}>Fields Involved</Text>,
      property: 'fieldsInvolved',
      render: (data) => (
        <Text color={getErrorColor(data.status)}>{data.fieldsInvolved}</Text>
      ),
    },
    {
      render: ({ totalAcres, status }) => {
        let totalAcresOutput;
        if (!totalAcres || isNaN(totalAcres)) {
          totalAcresOutput = '?';
        } else {
          totalAcresOutput = totalAcres.toFixed(3);
        }
        return (
          <Text
            color={getErrorColor(status)}
            data-testid='campaign-list-sample-point'
          >
            {totalAcresOutput}
          </Text>
        );
      },
      header: <Text style={{ fontWeight: 'bold' }}>Total Acres</Text>,
      property: 'totalAcres',
    },
    {
      header: <Text style={{ fontWeight: 'bold' }}>Total Points</Text>,
      property: 'samplingPoints',
      render: (data) => (
        <Text color={getErrorColor(data.status)}>{data.samplingPoints}</Text>
      ),
    },
    {
      header: (
        <Text style={{ fontWeight: 'bold' }}>Valid Collected Points</Text>
      ),
      property: 'validCollectedPoints',
      render: (data) => (
        <Text color={getErrorColor(data.status)}>
          {data.validCollectedPoints ?? '---'}
        </Text>
      ),
    },
    {
      render: ({ createdAt, status }) => {
        return (
          <Text color={getErrorColor(status)} data-testid='campaign-list-year'>
            {new Date(createdAt).toLocaleDateString()}
          </Text>
        );
      },
      header: <Text style={{ fontWeight: 'bold' }}>Created On</Text>,
      property: 'createdAt',
    },
    {
      render: (campaign) => {
        const { id, status } = campaign;

        if (!shouldShowError && isErrored(status)) {
          return <Box style={{ height: '56px' }} />;
        }

        return (
          <Box direction={'row'} gap={'medium'} style={{ height: '56px' }}>
            <ActionDropdown campaign={campaign} status={status} />
            <Anchor
              data-cy={'view-campaign-button'}
              onClick={() => handleCampaignDetails(id)}
              color='#244F7C'
              style={{
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {'View Campaign'}
            </Anchor>
          </Box>
        );
      },
      property: 'id',
      align: 'center',
    },
  ];

  const handleCreateCampaign = () => {
    dispatch(getResetCampaignCreationStateAction());
    const cancelModal = showModal(
      'Drop your file to start the campaign',
      <UploadCampaignModal
        salesforceId={salesforceId}
        journeyId={journeyId}
        closeModal={() => dispatch(cancelUploadFileStateAction())}
      />,
      null,
      null,
      false,
      '',
      '',
      'upload-campaign',
    );
    setCancelModal({ callback: cancelModal });
  };

  const handleCampaignDetails = (campaignId: string) => {
    navigate(`/campaigns/${campaignId}`);
  };

  return (
    <Box margin='1rem 1.5rem 0 1.5rem'>
      <Box
        direction='row-responsive'
        justify='between'
        align='center'
        margin={'8px 0'}
      >
        <Box align='left'>
          <Heading level={'2'} margin={'0'}>
            Soil Sampling Campaigns
          </Heading>
          {(salesforceId || journeyId) && (
            <Heading
              level={'4'}
              margin='0.2rem 0 0.4rem 0'
              style={{ whiteSpace: 'nowrap' }}
            >
              See{' '}
              {producer
                ? producer.Name
                : journey
                ? `${journey.firstName} ${journey.lastName}`
                : 'producer'}{' '}
              campaigns
            </Heading>
          )}
        </Box>
        <Box align='center' direction={'row'}>
          <Button
            data-cy={'create-campaign-button'}
            primary
            label='Create new campaign'
            onClick={handleCreateCampaign}
          />
        </Box>
      </Box>
      {campaigns?.length ? <CampaignListHeader /> : <></>}
      {campaignsFiltered?.length > 0 &&
      campaignFilter.lifeCycle === 'SUCCESS' ? (
        <Box
          align='left'
          margin='1rem 0 0 0'
          style={{ width: '100%' }}
          data-testid='sample-data-available'
        >
          <Box align='left' style={{ width: '100%' }}>
            <DataTable
              data-cy={'campaign-list-table'}
              columns={columns}
              fill={'horizontal'}
              data={campaignsFiltered}
              background={{
                body: ['#fff', '#F3F6F9'],
              }}
              pad={{
                header: '1rem 0.5rem 1rem 0.5rem',
                body: '0 0.5rem 0 0.5rem',
              }}
            />
          </Box>
        </Box>
      ) : campaignsFiltered === undefined ||
        campaignFilter.lifeCycle === 'PENDING' ? (
        <Box direction={'row'} align={'center'} pad={'0.5rem 1rem'}>
          <div style={{ margin: '2rem auto' }}>
            <Spinner color={'#FFE137'} size={'large'} />
          </div>
        </Box>
      ) : campaignsFiltered?.length === 0 ? (
        <Box
          justify='center'
          align='center'
          width='100%'
          data-testid='sample-data-not-available'
          margin={'1rem 0'}
        >
          <img alt='' src={campaignImg} height='150px' width='150px' />
          <Heading level={'3'} style={{ minWidth: 'fit-content' }}>
            {campaigns?.length === 0
              ? 'Create your first soil sampling campaign'
              : 'No campaigns found with those filters.'}
          </Heading>
          <Box align='center'>
            <Button
              data-testid='createFCampaign'
              style={{ padding: '0.3rem 0.6rem', maxWidth: '12rem' }}
              primary
              label='Create campaign'
              onClick={handleCreateCampaign}
            />
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};

export default CampaignListPage;
