import { Anchor, Box, DropButton, Spinner } from 'grommet';
import { ReactComponent as MoreVertical } from '../assets/more-vertical.svg';
import * as React from 'react';
import { useEffect, useState } from 'react';
import showModal from '../../../components/modal/modal';
import {
  getChangeCampaignStatusAction,
  getChangeCampaignNameAndVendorAction,
} from '../../../redux/actions/soil-campaign-actions';
import { useDispatch, useSelector } from 'react-redux';
import { showToast } from '../../../providers/ToastProvider';
import EditCampaignModal from './EditCampaignModal.component';
import { Statuses } from '../../campaign-upload-details/utils/enums';
import { useFlags } from 'launchdarkly-react-client-sdk';

const ActionDropdown = (props: { campaign: any; status: any }) => {
  const dispatch = useDispatch();
  const [openDropdown, setOpenDropdown] = useState(false);
  const [cancelModal, setCancelModal] = useState(null);
  const [showSpinner, setShowSpinner] = useState(false);
  const statusChangeState = useSelector((state: any) => {
    return state.soilCampaign.campaignChangeStatusState;
  });
  const { selectCampaignLabs, selectCampaignTracking } = useFlags();

  const newCampaignData: {
    campaignName: null | string;
    vendorId: null | string;
    labId: null | string;
    assessedStatus: null | string;
    vendorForCleanup: null | string;
    validCollectedPoints: null | number;
    notes: null | string;
  } = {
    campaignName: null,
    vendorId: null,
    labId: null,
    assessedStatus: null,
    vendorForCleanup: null,
    validCollectedPoints: null,
    notes: null,
  };
  useEffect(() => {
    if (statusChangeState.lifeCycle === 'SUCCESS') {
      cancelModal?.callback('SUCCESS');
    }
    if (statusChangeState.lifeCycle === 'ERROR') {
      showToast(
        'Error',
        "Sorry, we couldn't update the campaign status",
        'error',
      );
    }
    setOpenDropdown(false);
    setShowSpinner(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusChangeState, dispatch]);

  const handleChangeCampaignStatus = (campaignId: string, status: string) => {
    if (status !== Statuses['archived']) {
      const cancelModal = showModal(
        'Archive Campaign',
        'Are you sure you want to archive this campaign?',
        () => {
          setShowSpinner(true);
          dispatch(
            getChangeCampaignStatusAction(campaignId, Statuses['archived']),
          );
        },
        () => {
          setOpenDropdown(false);
          setShowSpinner(false);
        },
        true,
        'Okay',
        'Cancel',
        'archive-modal',
      );
      setCancelModal({ callback: cancelModal });
    } else {
      dispatch(
        getChangeCampaignStatusAction(campaignId, Statuses['in-review']),
      );
    }
  };

  const showEditCampaignModal = (campaign: any) => {
    const cancelModal = showModal(
      'Edit your campaign',
      <EditCampaignModal
        campaign={campaign}
        campaignPropUpdated={(prop: string, value: string | number) => {
          // @ts-ignore
          newCampaignData[prop] = value;
        }}
        showCampaignLab={selectCampaignLabs}
        showCampaignTracking={selectCampaignTracking}
      />,
      () => {
        dispatch(
          getChangeCampaignNameAndVendorAction(campaign.id, newCampaignData),
        );
        setOpenDropdown(false);
      },
      () => {
        setOpenDropdown(false);
        setShowSpinner(false);
      },
      true,
      'Okay',
      'Cancel',
      'edit-modal',
    );
    setCancelModal({ callback: cancelModal });
  };

  return (
    <DropButton
      data-cy={`campaign-kebab`}
      style={{ border: 0 }}
      label={<MoreVertical />}
      dropAlign={{ right: 'left' }}
      open={openDropdown}
      onOpen={() => setOpenDropdown(true)}
      onClose={() => setOpenDropdown(false)}
      dropContent={
        <Box
          pad='medium'
          direction={'column'}
          gap={'small'}
          style={{
            background: '#FFFFFF',
            minWidth: '150px',
            border: '1px solid #244F7C',
            boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.5)',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Anchor
            data-cy={'edit-campaign-anchor'}
            alignSelf={'start'}
            onClick={() => {
              showEditCampaignModal(props.campaign);
            }}
            color={'#232735'}
          >
            Edit Campaign
          </Anchor>
          <Box
            direction={'row'}
            gap={'small'}
            style={{
              minWidth: '150px',
              display: 'flex',
            }}
          >
            <Anchor
              data-cy={'archive-campaign-anchor'}
              alignSelf={'start'}
              onClick={() => {
                handleChangeCampaignStatus(props.campaign.id, props.status);
              }}
              color={'#232735'}
            >
              {props.status === Statuses['archived'] ? 'Unarchive' : 'Archive'}
            </Anchor>
            {showSpinner && <Spinner color={'#FFE137'} size={'xsmall'} />}
          </Box>
        </Box>
      }
    />
  );
};

export default ActionDropdown;
