import { Box, RadioButtonGroup, Spinner, Text } from 'grommet';

const ChangeStatusInBulkComponent = (props: {
  selectedPoints: any;
  bulkEditStatus: any;
  setBulkEditStatus: any;
  showStatusSpinner: any;
}) => {
  return (
    <Box pad={'medium'}>
      <Box
        direction={'row'}
        gap={'medium'}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <Text
          style={{
            fontWeight: 800,
            fontSize: '20px',
            lineHeight: '32px',
          }}
        >
          Change status:
        </Text>
        {props.showStatusSpinner && (
          <Spinner color={'#FFE137'} size={'small'} />
        )}
      </Box>

      <Text
        style={{
          fontWeight: 400,
          fontSize: '14px',
          color: 'rgb(128, 128, 128)',
          lineHeight: '32px',
        }}
      >
        {props.selectedPoints.length}{' '}
        {props.selectedPoints.length !== 1 ? 'points' : 'point'} selected
      </Text>

      <Box
        pad={'small'}
        style={{
          fontSize: '14px',
          borderTop: 'solid 1px #dedede',
          paddingTop: '1rem',
        }}
      >
        <RadioButtonGroup
          name='pointsStatus'
          options={[
            { label: 'Approve', value: 'APPROVED' },
            {
              label: 'In Review',
              value: 'IN_REVIEW',
            },
            { label: 'Reject', value: 'REJECTED' },
          ]}
          value={props.bulkEditStatus}
          onChange={(event) => props.setBulkEditStatus(event.target.value)}
        />
      </Box>
    </Box>
  );
};

export default ChangeStatusInBulkComponent;
