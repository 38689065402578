import { Box, Select, Text, TextArea, TextInput, ThemeContext } from 'grommet';
import { useSelector } from 'react-redux';
import { getCampaignLab } from '../../../common/helpers';
import { useState } from 'react';
import { css } from 'styled-components';

const EditCampaignModal = ({
  campaign,
  campaignPropUpdated,
  showCampaignLab,
  showCampaignTracking,
}: any) => {
  const campaignLab = getCampaignLab(campaign);
  const vendors = useSelector(({ soilCampaign }: any) => soilCampaign.vendors);
  const labs = useSelector(({ soilCampaign }: any) => soilCampaign.labs);
  const list = campaignLab.map((item: string) => labs[item]).join(', ');

  const samplingPointsLength =
    campaign.samplingPoints.length ?? campaign.samplingPoints;

  const [validCollectedPoints, setValidCollectedPoints] = useState<
    number | string
  >(campaign.validCollectedPoints ?? '');
  const [notes, setNotes] = useState<string>(campaign.notes);

  return (
    <ThemeContext.Extend
      value={{
        select: {
          container: {
            padding: 0,
            extend: () => css`
              padding: 0;
            `,
          },
        },
        button: {
          extend: () => css`
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            padding: 10px;
            min-height: 40px;
          `,
        },
      }}
    >
      <Box>
        <Text
          style={{ fontSize: '16px', lineHeight: '20px', marginBottom: '4px' }}
        >
          Campaign Name
        </Text>
        <TextInput
          data-cy={'edit-modal-name'}
          defaultValue={campaign.name}
          style={{ padding: '10px', fontSize: '16px', lineHeight: '20px' }}
          onChange={(event) =>
            campaignPropUpdated('campaignName', event.target.value)
          }
          name='name'
        />
        <Text
          style={{
            marginTop: '16px',
            fontSize: '16px',
            lineHeight: '20px',
            marginBottom: '4px',
          }}
        >
          Campaign Vendor
        </Text>
        <Select
          style={{ padding: 0 }}
          data-cy={'edit-modal-vendor'}
          defaultValue={campaign.vendorId}
          focusIndicator={false}
          options={Object.keys(vendors).map((key) => ({
            id: key,
            name: vendors[key],
          }))}
          labelKey={'name'}
          valueKey={{ key: 'id', reduce: true }}
          onChange={(event) =>
            campaignPropUpdated('vendorId', event.target.value)
          }
          name='vendor'
        />
        {showCampaignLab && (
          <>
            <Text
              style={{
                marginTop: '16px',
                fontSize: '16px',
                lineHeight: '20px',
                marginBottom: '4px',
              }}
            >
              Campaign Lab
            </Text>
            {campaignLab.length <= 1 ? (
              <Select
                style={{ padding: 0 }}
                data-cy={'edit-modal-lab'}
                defaultValue={campaignLab[0]}
                focusIndicator={false}
                options={Object.keys(labs).map((key) => {
                  return { id: key, name: labs[key] };
                })}
                labelKey={'name'}
                placeholder={'Select'}
                valueKey={{ key: 'id', reduce: true }}
                onChange={(event) =>
                  campaignPropUpdated('labId', event.target.value)
                }
                name='vendor'
                disabled={campaignLab.length > 1}
              />
            ) : (
              <Box
                border={{ style: 'solid', color: '#CCCCCC', size: '1px' }}
                width={'26.3rem'}
                style={{
                  padding: '10px',
                  fontSize: '16px',
                  lineHeight: '20px',
                }}
              >
                <Text weight={'bold'} size={'inherit'} wordBreak={'break-word'}>
                  {list}
                </Text>
              </Box>
            )}
          </>
        )}
        {showCampaignTracking && (
          <>
            <Text
              style={{
                marginTop: '16px',
                fontSize: '16px',
                lineHeight: '20px',
                marginBottom: '4px',
              }}
            >
              Agoro Assessed Status
            </Text>
            <Select
              style={{ padding: 0 }}
              data-cy={'edit-modal-assessed-status'}
              defaultValue={campaign.assessedStatus}
              focusIndicator={false}
              options={[
                'Complete Valid Data - No Clean Up Required',
                'Awaiting Strat. Team Assessment',
                'Locations or Data Missing Requiring Clean-Up',
              ]}
              onChange={(event) =>
                campaignPropUpdated('assessedStatus', event.target.value)
              }
              name='assessed-status'
            />
            <Text
              style={{
                marginTop: '16px',
                fontSize: '16px',
                lineHeight: '20px',
                marginBottom: '4px',
              }}
            >
              Vendor Assigned For Clean Up
            </Text>
            <Select
              style={{ padding: 0 }}
              data-cy={'edit-modal-vendor-for-cleanup'}
              defaultValue={campaign.vendorForCleanupId}
              focusIndicator={false}
              options={Object.keys(vendors).map((key) => ({
                id: key,
                name: vendors[key],
              }))}
              labelKey={'name'}
              valueKey={{ key: 'id', reduce: true }}
              onChange={(event) =>
                campaignPropUpdated('vendorForCleanup', event.target.value)
              }
              name='vendor-for-cleanup'
            />
            <Box direction={'row'} gap={'16px'}>
              <Box gap={'4px'}>
                <Text
                  style={{
                    marginTop: '16px',
                    fontSize: '16px',
                    lineHeight: '20px',
                  }}
                >
                  Valid Collected Points
                </Text>
                <TextInput
                  data-cy={'edit-modal-valid-collected-points'}
                  value={validCollectedPoints}
                  style={{
                    padding: '10px',
                    fontSize: '16px',
                    lineHeight: '20px',
                  }}
                  onChange={(event) => {
                    if (
                      !/^[0-9]*$/.test(event.target.value) ||
                      Number(event.target.value) > samplingPointsLength
                    ) {
                      return;
                    }
                    setValidCollectedPoints(
                      event.target.value ? Number(event.target.value) : '',
                    );
                    campaignPropUpdated(
                      'validCollectedPoints',
                      Number(event.target.value),
                    );
                  }}
                  name='valid-collected-points'
                />
              </Box>
              <Box gap={'4px'}>
                <Text
                  style={{
                    marginTop: '16px',
                    fontSize: '16px',
                    lineHeight: '20px',
                  }}
                >
                  Total Points
                </Text>
                <TextInput
                  data-cy={'edit-modal-total-points'}
                  disabled
                  value={samplingPointsLength}
                  style={{
                    padding: '10px',
                    fontSize: '16px',
                    lineHeight: '20px',
                  }}
                  name='total-points'
                />
              </Box>
            </Box>
            <Text
              style={{
                marginTop: '16px',
                fontSize: '16px',
                lineHeight: '20px',
              }}
            >
              Notes
            </Text>
            <TextArea
              data-cy={'notes-input'}
              value={notes}
              onChange={(event) => {
                setNotes(event.target.value);
                campaignPropUpdated('notes', event.target.value);
              }}
              name='notes'
              size={'medium'}
              resize={'vertical'}
              placeholder={'Notes'}
              style={{
                marginTop: '4px',
                borderRadius: '1px',
                borderColor: '#CCCCCC',
                borderWidth: '1px',
                padding: '10px',
                minHeight: '66px',
                fontSize: '16px',
              }}
            />
          </>
        )}
      </Box>
    </ThemeContext.Extend>
  );
};

export default EditCampaignModal;
